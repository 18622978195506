import classNames from 'classnames';

export interface TypographyProps {
  children?: React.ReactNode;
  size?: 'tiny' | 'smaller' | 'small' | 'normal' | 'medium' | 'large';
  breaks?: boolean;
  noMargin?: boolean;
  opaque?: boolean;
}

export default function Typography({
  children,
  size,
  breaks,
  noMargin,
  opaque
}: TypographyProps) {
  const TypographyClasses = classNames('typography', {
    'typography--tiny': size === 'tiny',
    'typography--smaller': size === 'smaller',
    'typography--small': size === 'small',
    'typography--normal': size === 'normal',
    'typography--medium': size === 'medium',
    'typography--large': size === 'large',
    'typography--breaks': breaks,
    'typography--nomargin': noMargin,
    opaque: opaque
  });
  return <p className={TypographyClasses}>{children}</p>;
}

import classNames from 'classnames';
import { useIntl } from 'react-intl';

export default function OrSeperator({ small = false }: { small?: boolean }) {
  const intl = useIntl();

  const className = classNames('or-seperator', {
    'or-seperator--small': small
  });

  return <div className={className}>{intl.formatMessage({ id: 'or' })}</div>;
}

// Create a union type of possible step numbers
export type StepNumber = 1 | 2 | 3 | 4 | 5 | 6;

// Use template literal type to create all possible combinations
type CreateProjectStep = `create_project_step_${StepNumber}`;

export type SidebarMarkerType =
  | 'end'
  | 'material'
  | 'invitation'
  | 'briefing'
  | 'painpoint_description'
  | 'painpoint_question'
  | 'benchmarks'
  | 'solutions'
  | 'hypothesis'
  | 'userflow'
  | 'prototype_description'
  | 'hypothesis_details'
  | CreateProjectStep;

export const SidebarMarker = ({
  type,
  children
}: {
  type: SidebarMarkerType;
  children?: any;
}) => (
  <>
    <div
      id={'assistantScrollContainer_' + type}
      style={{ height: '1px', margin: 0, padding: 0 }}
    />
    {children}
  </>
);

export default SidebarMarker;

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import CreateProjectScreen from 'screens/ProjectsListScreen/CreateProjectScreen';
import { CreateProjectProvider } from 'contexts/CreateProjectContext';
import CreateProjectManualScreen from 'screens/CreateProjectScreen/CreateProjectManualScreen';
import TopicAndChallengeScreen from 'screens/CreateProjectScreen/TopicAndChallengeScreen';
import SelectStrategyType from 'screens/CreateProjectScreen/SelectStrategyTypeScreen';
import {
  ROUTE_CREATE_PROJECT,
  ROUTE_CREATE_PROJECT_GUIDED,
  ROUTE_CREATE_PROJECT_MANUAL
} from 'utils/constants/routes';
import TargetGroupScreen from 'screens/CreateProjectScreen/TargetGroupScreen';
import ServiceScreen from 'screens/CreateProjectScreen/ServiceScreen';
import BusinessTargetAndFormatScreen from 'screens/CreateProjectScreen/BusinessTargetAndFormatScreen';
import SelectQuestionScreen from 'screens/CreateProjectScreen/SelectQuestionScreen';
import SelectStrategyTypeScreen from 'screens/CreateProjectScreen/SelectStrategyTypeScreen';
import CreateProjectGuidedContainer from 'screens/CreateProjectScreen/CreateProjectGuidedContainer';

const CreateProjectRouter = observer(() => {
  const match = useRouteMatch<{ orgId: string }>();
  const orgId = match.params.orgId;

  if (!orgId) {
    throw new Error('Organization ID is required');
  }

  return (
    <CreateProjectProvider>
      <CreateProjectScreen>
        <Switch>
          <Route
            path={ROUTE_CREATE_PROJECT_GUIDED}
            exact={true}
            render={({ match }) => {
              return (
                <CreateProjectGuidedContainer>
                  {match.params.step === '1' && <SelectStrategyTypeScreen />}
                  {match.params.step === '2' && <TopicAndChallengeScreen />}
                  {match.params.step === '3' && <TargetGroupScreen />}
                  {match.params.step === '4' && <ServiceScreen />}
                  {match.params.step === '5' && (
                    <BusinessTargetAndFormatScreen />
                  )}
                  {match.params.step === '6' && <SelectQuestionScreen />}
                </CreateProjectGuidedContainer>
              );
            }}
          />
          <Route
            path={ROUTE_CREATE_PROJECT_MANUAL}
            exact={true}
            render={({ match }) => {
              return (
                <>
                  {match.params.step === '1' && <SelectStrategyTypeScreen />}
                  {match.params.step === '2' && <CreateProjectManualScreen />}
                </>
              );
            }}
          />
          <Route path={ROUTE_CREATE_PROJECT} component={SelectStrategyType} />
        </Switch>
      </CreateProjectScreen>
    </CreateProjectProvider>
  );
});

export default CreateProjectRouter;

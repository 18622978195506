import classNames from 'classnames';
import Icon from 'components/Icon';

import { IconNameType } from 'components/Icon/Icon';

interface CardHeaderProps {
  logo?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  simple?: boolean;
  single?: boolean;
  lineHeight?: 'auto';
  onEditClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  editIcon?: IconNameType;
}

export default function CardHeader({
  logo,
  title,
  subtitle,
  simple,
  single,
  onEditClick,
  editIcon,
  lineHeight
}: CardHeaderProps) {
  const headerClasses = classNames('card-header', {
    'card-header--edit': !!onEditClick
  });

  const titleClasses = classNames('card-header__title', {
    'card-header__title--simple': simple,
    'card-header__title--single': single,
    'card-header__title--line-height-auto': lineHeight === 'auto'
  });

  const headerTitle = () => {
    if (subtitle) {
      return (
        <>
          <h1 className={titleClasses}>{title}</h1>
          <h2 className="card-header__subtitle">{subtitle}</h2>
        </>
      );
    } else {
      return (
        <>
          <h1 className={titleClasses}>{title}</h1>
        </>
      );
    }
  };

  const headerInner = () => {
    if (!!onEditClick) {
      return (
        <div className="card-header__inner">
          {headerTitle()}
          <button className="card-header__edit-button" onClick={onEditClick}>
            <Icon name={editIcon || 'pen'} />
          </button>
        </div>
      );
    } else {
      return <div className="card-header__inner">{headerTitle()}</div>;
    }
  };

  if (logo) {
    return (
      <header className={headerClasses}>
        {logo}
        {headerInner()}
      </header>
    );
  } else {
    return <header className={headerClasses}>{headerInner()}</header>;
  }
}

import { useCreateProject } from 'contexts/CreateProjectContext';
import { FormattedMessage } from 'react-intl';
import SidebarMarker, {
  StepNumber
} from 'domain/assistant/AssistantSidebar/SidebarMarker';
import SidebarItem from 'domain/assistant/AssistantSidebar/SidebarItem';
import SidebarItemHeader from 'domain/assistant/AssistantSidebar/SidebarItemHeader';
import CardHeader from 'components/CardHeader';
import RainbowCardSmall from 'components/RainbowCard/RainbowCardSmall';
import { STEP_CONFIGS } from 'screens/ProjectsListScreen/CreateProjectScreen';
import Flow from 'components/Layout/Flow';
import SidebarItemContent from 'domain/assistant/AssistantSidebar/SidebarItemContent';
import Typography from 'components/Typography';

const CreateProjectSidebar = () => {
  const {
    selectedStrategyType,
    projectTopic,
    projectChallenge,
    projectTargetGroup,
    projectService,
    projectBusinessTarget,
    projectBusinessTargetFormat,
    currentStep,
    selectedQuestion,
    setCurrentStep
  } = useCreateProject();

  const steps = [
    {
      step: 1 as StepNumber,
      isCompleted: !!selectedStrategyType,
      fields: [
        {
          labelId: 'create project strategy type',
          value: selectedStrategyType?.type
        }
      ]
    },
    {
      step: 2 as StepNumber,
      isCompleted: !!(projectTopic || projectChallenge),
      fields: [
        { labelId: 'create project topic', value: projectTopic },
        { labelId: 'create project challenge', value: projectChallenge }
      ]
    },
    {
      step: 3 as StepNumber,
      isCompleted: !!projectTargetGroup,
      fields: [
        { labelId: 'create project target group', value: projectTargetGroup }
      ]
    },
    {
      step: 4 as StepNumber,
      isCompleted: !!projectService,
      fields: [{ labelId: 'create project service', value: projectService }]
    },
    {
      step: 5 as StepNumber,
      isCompleted: !!(projectBusinessTarget || projectBusinessTargetFormat),
      fields: [
        {
          labelId: 'create project business target',
          value: projectBusinessTarget
        },
        {
          labelId: 'create project business target format',
          value: projectBusinessTargetFormat
        }
      ]
    },
    {
      step: 6 as StepNumber,
      isCompleted: !!selectedQuestion,
      fields: [
        {
          labelId: 'create project select final project question',
          value: selectedQuestion || ''
        }
      ]
    }
  ];

  return (
    <div style={{ marginTop: '-1.5rem' }}>
      <Flow flowSpace="2.5rem">
        {steps.map((step) => {
          return (
            <SidebarMarker
              type={`create_project_step_${step.step}`}
              key={step.step}
            >
              <SidebarItem
                key={step.step}
                onClick={() => setCurrentStep(step.step)}
              >
                {(currentStep >= step.step || step.isCompleted) && (
                  <SidebarItemHeader>
                    <FormattedMessage
                      id={STEP_CONFIGS[step.step].sidebarDescription}
                    />
                  </SidebarItemHeader>
                )}
                {step.isCompleted && (
                  <SidebarItemContent>
                    <RainbowCardSmall shrink={true}>
                      <CardHeader
                        simple={true}
                        lineHeight={'auto'}
                        title={
                          <FormattedMessage
                            id={STEP_CONFIGS[step.step].sidebarTitle}
                          />
                        }
                      />
                      {step.fields.map((field, index) => {
                        if (!field.value) return null;
                        return (
                          <div
                            key={index}
                            style={{
                              marginBottom:
                                step.fields.length > 1 &&
                                index >= 0 &&
                                index !== step.fields.length - 1
                                  ? '1.5rem'
                                  : undefined
                            }}
                          >
                            <Typography size={'small'}>
                              {field.value?.length > 100
                                ? field.value?.substring(0, 100) + '…'
                                : field.value}
                            </Typography>
                          </div>
                        );
                      })}
                    </RainbowCardSmall>
                  </SidebarItemContent>
                )}
              </SidebarItem>
            </SidebarMarker>
          );

          return null;
        })}
      </Flow>
    </div>
  );
};

export default CreateProjectSidebar;

import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import ErrorMessage from 'components/ErrorMessage';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import StepContainer from 'domain/assistant/StepContainer';
import {
  SessionTop,
  SessionHeadline,
  SessionMainText
} from 'domain/assistant/session-elements';
import { useStores } from 'index';
import LinkRow from 'components/LinkRow';
import { useHistory } from 'react-router-dom';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { ROUTE_CREATE_PROJECT, ROUTE_ORG } from 'utils/constants/routes';
import CreateProjectSidebar from 'screens/CreateProjectScreen/CreateProjectSidebar';

interface StepConfig {
  headline: string;
  mainText: string;
  sidebarTitle: string;
  sidebarDescription: string;
  showModeSwitch?: boolean;
  videoId?: string;
}

export const STEP_CONFIGS: Record<number, StepConfig> = {
  1: {
    headline: 'create project select strategy type',
    mainText: 'create project select strategy type description',
    sidebarTitle: 'create project select strategy type sidebar title',
    sidebarDescription:
      'create project select strategy type sidebar description',
    showModeSwitch: true
  },
  2: {
    headline: 'create project topic and challenge',
    mainText: 'create project topic and challenge description',
    sidebarTitle: 'create project topic and challenge sidebar title',
    sidebarDescription: 'create project topic and challenge sidebar description'
  },
  3: {
    headline: 'create project target group',
    mainText: 'create project target group description',
    sidebarTitle: 'create project target group sidebar title',
    sidebarDescription: 'create project target group sidebar description'
  },
  4: {
    headline: 'create project service',
    mainText: 'create project service description',
    sidebarTitle: 'create project service sidebar title',
    sidebarDescription: 'create project service sidebar description'
  },
  5: {
    headline: 'create project business target',
    mainText: 'create project business target description',
    sidebarTitle: 'create project business target sidebar title',
    sidebarDescription: 'create project business target sidebar description'
  },
  6: {
    headline: 'create project select final project question',
    mainText: 'create project select final project question description',
    sidebarTitle: 'create project select final project question sidebar title',
    sidebarDescription:
      'create project select final project question sidebar description'
  }
};

interface CreateProjectScreenProps {
  children?: React.ReactNode;
}

function CreateProjectScreen({ children }: CreateProjectScreenProps) {
  const history = useHistory();
  const { dataStore } = useStores();
  const intl = useIntl();

  const {
    guided,
    setGuided,
    currentStep,
    setCurrentStep,
    orgId,
    selectedQuestion
  } = useCreateProject();

  const getStepProgress = () => {
    const totalSteps = guided ? 6 : 2;

    let calculateStep = currentStep - 1;
    if (selectedQuestion && currentStep - 1 == totalSteps - 1) {
      calculateStep = totalSteps;
    }
    return (calculateStep / totalSteps) * 100;
  };

  const renderModeSwitch = () => {
    if (!STEP_CONFIGS[currentStep]?.showModeSwitch) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: '4.6rem',
          left: 0,
          right: 0,
          marginBottom: '1.8rem'
        }}
      >
        <LinkRow
          links={[
            {
              onClick: () => {
                setGuided(true);
                setCurrentStep(1);
              },
              content: 'Guided',
              isLink: !guided,
              to: '#guided'
            },
            {
              onClick: () => {
                setGuided(false);
                setCurrentStep(1);
                history.replace(
                  ROUTE_CREATE_PROJECT.replace(':orgId', orgId!.toString())
                );
              },
              content: 'Manual',
              isLink: guided,
              to: '#manual'
            }
          ]}
        />
      </div>
    );
  };

  const renderStepHeader = () => {
    const config = STEP_CONFIGS[currentStep || 1];

    if (!config) return null;

    return (
      <SessionTop>
        {renderModeSwitch()}
        <SessionHeadline step={`${currentStep}/${guided ? 6 : 2}`}>
          <FormattedMessage id={config.headline} />
        </SessionHeadline>

        <SessionMainText>
          <FormattedMessage id={config.mainText} />
        </SessionMainText>
      </SessionTop>
    );
  };

  const org = dataStore.currentOrganization;
  if (!org) {
    return (
      <AppLayoutReduced active="customers" withSettings={true}>
        <ErrorMessage state="save_error" />
      </AppLayoutReduced>
    );
  }

  return (
    <AppLayoutReduced active="customers" withSettings={true}>
      <StepContainer
        minHeightZero={true}
        progress={getStepProgress()}
        sidebarHeadline={intl.formatMessage({
          id: 'create project sidebar headline'
        })}
        sidebarContent={<CreateProjectSidebar />}
        onCloseClick={() => {
          if (
            window.confirm(
              intl.formatMessage({ id: 'create project cancel confirmation' })
            )
          ) {
            history.push(ROUTE_ORG.replace(':orgId', orgId!.toString()));
          }
        }}
      >
        {renderStepHeader()}
        <div style={{ marginTop: '2rem', marginBottom: '6rem' }}>
          {children}
        </div>
      </StepContainer>
    </AppLayoutReduced>
  );
}

export default observer(CreateProjectScreen);

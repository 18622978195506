import classNames from 'classnames';

export interface StrategyType {
  id: string;
  type: string;
  description: string;
  icon: string;
}

interface ProjectTypeProps {
  strategyTypes: StrategyType[];
  onStrategyTypeChanged: (strategyType: StrategyType) => void;
  selectedStrategyType?: StrategyType;
}

export default function ProjectType({
  strategyTypes,
  onStrategyTypeChanged,
  selectedStrategyType
}: ProjectTypeProps) {
  return (
    <div className="project-type" style={{}}>
      {strategyTypes.map((strategyType) => (
        <div
          key={strategyType.id}
          className={classNames('project-type-card', {
            selected: selectedStrategyType?.id === strategyType.id
          })}
        >
          <input
            type="radio"
            id={strategyType.id}
            name="selectedStrategyType"
            checked={selectedStrategyType?.id === strategyType.id}
            value={strategyType.id}
            onChange={() => {
              onStrategyTypeChanged && onStrategyTypeChanged(strategyType);
            }}
          />
          <label htmlFor={strategyType.id}>
            <div className="project-type-body">
              <img src={strategyType.icon} />
              <div className="project-type-body__title">
                {strategyType.type}
              </div>
              <p className="project-type-body__description">
                {strategyType.description}
              </p>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
}

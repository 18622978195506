import { Link, LinkProps } from 'react-router-dom';

export type LinkIfProps = {
  isLink?: boolean;
  onClick?: () => void;
} & LinkProps;

/**
 * A `Link` if the `isLink` prop is `true`, else a simple `div`
 * with the same `classname`.
 *
 * Example:
 *     <LinkIf isLink={condition} to="/" className="my-link"/>
 */
export default ({ isLink, className, children, ...rest }: LinkIfProps) =>
  isLink ? (
    <Link className={className} {...rest}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

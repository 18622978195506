import { useCreateProject } from 'contexts/CreateProjectContext';
import { StepNumber } from 'domain/assistant/AssistantSidebar/SidebarMarker';
import { scrollSidebar } from 'domain/assistant/StepContainer';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ROUTE_CREATE_PROJECT_GUIDED } from 'utils/constants/routes';

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function CreateProjectGuidedContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const history = useHistory();
  const match = useRouteMatch<{ orgId: string; step: string }>();
  const {
    selectedStrategyType,
    projectTopic,
    projectChallenge,
    projectTargetGroup,
    projectService,
    projectBusinessTarget,
    projectBusinessTargetFormat,
    currentStep,
    setCurrentStep,
    submitted
  } = useCreateProject();
  const prevStep = usePrevious(currentStep);

  // Guard effect to check required data for each step
  // redirect to step 1 if the user has not filled out the form
  useEffect(() => {
    const currentStepNumber = parseInt(match.params.step, 10);

    // Define required data for each step
    const stepRequirements = {
      2: () => !selectedStrategyType,
      3: () => !projectTopic && !projectChallenge,
      4: () => !projectTargetGroup,
      5: () => !projectService,
      6: () => !projectBusinessTarget || !projectBusinessTargetFormat
    };

    // If we're not on step 1 and the previous step's requirements aren't met
    if (
      currentStepNumber > 1 &&
      stepRequirements[currentStepNumber as keyof typeof stepRequirements]?.()
    ) {
      setCurrentStep(1);
      history.replace(
        ROUTE_CREATE_PROJECT_GUIDED.replace(
          ':orgId',
          match.params.orgId
        ).replace(':step', '1')
      );
    }

    scrollSidebar(
      `create_project_step_${parseInt(match.params.step) as StepNumber}`
    );
  }, [match.params.step]);

  // Check if the user has filled out the form before leaving the page
  useEffect(() => {
    const hasFormData =
      projectTopic ||
      projectChallenge ||
      projectTargetGroup ||
      projectService ||
      projectBusinessTarget ||
      projectBusinessTargetFormat;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasFormData) {
        e.preventDefault();
      }
    };

    if (hasFormData && !submitted) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () =>
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [
    projectTopic,
    projectChallenge,
    projectTargetGroup,
    projectService,
    projectBusinessTarget,
    projectBusinessTargetFormat,
    submitted
  ]);

  // handle all next/back clicks
  useEffect(() => {
    if (prevStep && prevStep !== currentStep) {
      const newUrl = ROUTE_CREATE_PROJECT_GUIDED.replace(
        ':orgId',
        match.params.orgId.toString()
      ).replace(':step', currentStep.toString());

      // Only push if we're actually changing URLs
      if (history.location.pathname !== newUrl) {
        history.push(newUrl);
      }
    }
  }, [currentStep, prevStep]);

  return <>{children}</>;
}

export default observer(CreateProjectGuidedContainer);

import FloatButton from 'components/FloatButton';
import FloatingSearchForm from 'components/FloatingSearchForm';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { useIntl } from 'react-intl';

import { DataStoreType } from 'models/DataStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import ContextAwareAddElementButton from './ContextAwareAddElementButton';
import InviteButton from './InviteButton';

interface SettingsNavItemProps {
  applicationStore?: ApplicationStoreType;
  showAdd?: boolean;
  showInvite?: boolean;
  hideSearch?: boolean;
  painpointsStore?: PainpointsStoreType;
  projectsStore?: ProjectsStoreType;
  dataStore?: DataStoreType;
  showAI?: boolean;
}

// TODO move call of this comp to highest level possible as soon as add menu is available
export default inject(
  'applicationStore',
  'painpointsStore'
)(
  observer(
    ({
      applicationStore,
      showAdd,
      showInvite,
      hideSearch,
      showAI
    }: SettingsNavItemProps) => {
      const intl = useIntl();

      return (
        <OverlayWrapper bottomRight={true} stack={true}>
          {!hideSearch && <FloatingSearchForm />}

          {showInvite && <InviteButton />}

          {showAdd && <ContextAwareAddElementButton />}

          <FloatButton
            label={intl.formatMessage({ id: 'Menu' })}
            type="menu"
            active={!!applicationStore?.menuVisible}
            onClick={() => applicationStore!.toggleMenuVisible()}
          />
        </OverlayWrapper>
      );
    }
  )
);

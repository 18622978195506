import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import MainButton from 'components/MainButton';
import ToggleSwitch from 'components/ToggleSwitch';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { useStores } from 'index';
import { createServerTagList } from 'models/TagListModel';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import InnerLinkHelper from 'screens/ProjectsListScreen/InnerLinkHelper';
import { ROUTE_CREATE_PROJECT_GUIDED, ROUTE_LAB } from 'utils/constants/routes';
import useForm, { handleFormError } from 'utils/hooks/useForm';

export default function CreateProjectManualScreen() {
  const form = useForm();
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{ step: string; orgId: string }>();

  const { projectsStore, dataStore, organizationsStore } = useStores();
  const { selectedStrategyType } = useCreateProject();

  useEffect(() => {
    organizationsStore.getTargetGroups();
    organizationsStore.getServices();
  }, []);

  useEffect(() => {
    const currentStepNumber = parseInt(match.params.step, 10);

    // If we're not on step 1 and the previous step's requirements aren't met
    if (currentStepNumber > 1 && !selectedStrategyType) {
      history.replace(
        ROUTE_CREATE_PROJECT_GUIDED.replace(
          ':orgId',
          match.params.orgId
        ).replace(':step', '1')
      );
    }
  }, [match.params.step]);

  const targetGroups = [...dataStore.orgTargetGroups.values()];
  const services = [...dataStore.orgServices.values()];

  const addProject = async () => {
    if (!dataStore.currentOrganizationId) {
      return;
    }
    const project = {
      topic: form.values.topic || '',
      private: form.values.private || false,
      gpt_setting_id: selectedStrategyType!.id || '',
      briefing_attributes: {
        question: form.values.question || '',
        description: form.values.description || '',
        ai_target_group_description:
          form.values.ai_target_group_description || '',
        ai_service_description: form.values.ai_service_description || '',
        ai_business_target_description:
          form.values.ai_business_target_description || '',
        ai_characteristic_description:
          form.values.ai_characteristic_description || '',
        tags_attributes: form.values.tags
          ? createServerTagList(form.values.tags)
          : undefined
      }
    };
    try {
      const newProject = await projectsStore.createProject(
        project,
        dataStore.currentOrganizationId
      );

      if (!projectsStore.isItemSaveError) {
        if (newProject && newProject.id) {
          history.replace(
            ROUTE_LAB +
              '/' +
              dataStore.currentOrganizationId +
              '/' +
              newProject.id
          );
        } else {
          history.replace(ROUTE_LAB + '/' + dataStore.currentOrganizationId);
        }
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  };

  return (
    <Form loading={form.loading} onSubmit={addProject}>
      <FormTagList form={form} field="tags" allowAdd={true} />

      <ColumnWrapper gap="2em">
        {projectsStore.isItemSaveError && (
          <ErrorMessage
            state={projectsStore.itemLoadingState}
            onRetry={addProject}
          />
        )}

        <CardWrapper>
          <Input
            name="topic"
            label={<FormattedMessage id="Project topic" />}
            autoFocus={true}
            maxLength={30}
            normalFont={true}
            {...form.bindInput('topic')}
          />
        </CardWrapper>

        <CardWrapper>
          <Textarea
            lines={6}
            largeFont={true}
            label={<FormattedMessage id="briefing question" />}
            placeholder={intl.formatMessage({
              id: 'briefing question placeholder'
            })}
            name="question"
            normalFont={true}
            {...form.bindInput('question')}
            onKeyDown={(e: any) => {
              if (e.charCode === 13) {
                e.preventDefault();
                addProject();
              }
            }}
          />
        </CardWrapper>

        <CardWrapper>
          <Textarea
            name="description"
            label={<FormattedMessage id="Project short description" />}
            normalFont={true}
            {...form.bindInput('description')}
          />
        </CardWrapper>

        <CardWrapper>
          <InnerLinkHelper
            href={
              ROUTE_LAB +
              '/' +
              dataStore.currentOrganizationId +
              '/settings/target-groups'
            }
            label={intl.formatMessage({ id: 'Manage target groups' })}
          />
          <Select
            name="ai_target_group_description"
            label={<FormattedMessage id="Target group" />}
            normalFont={true}
            onFocus={() => {
              organizationsStore.getTargetGroups();
            }}
            {...form.bindInput('ai_target_group_description')}
          >
            <option value="">
              <FormattedMessage
                id={
                  targetGroups.length === 0
                    ? 'No target groups'
                    : 'Select target group'
                }
              />
            </option>
            {targetGroups.map((targetGroup) => (
              <option key={targetGroup.id} value={targetGroup.content!}>
                {targetGroup.name}
              </option>
            ))}
          </Select>
        </CardWrapper>

        <CardWrapper>
          <InnerLinkHelper
            href={
              ROUTE_LAB +
              '/' +
              dataStore.currentOrganizationId +
              '/settings/services'
            }
            label={intl.formatMessage({ id: 'Manage services' })}
          />
          <Select
            name="ai_service_description"
            label={<FormattedMessage id="Service" />}
            normalFont={true}
            onFocus={() => {
              organizationsStore.getServices();
            }}
            {...form.bindInput('ai_service_description')}
          >
            <option value="">
              <FormattedMessage
                id={services.length === 0 ? 'No services' : 'Select service'}
              />
            </option>
            {services.map((service) => (
              <option key={service.id} value={service.content!}>
                {service.name}
              </option>
            ))}
          </Select>
        </CardWrapper>

        <CardWrapper>
          <Input
            name="ai_business_target_description"
            label={<FormattedMessage id="Business target" />}
            normalFont={true}
            {...form.bindInput('ai_business_target_description')}
          />
        </CardWrapper>

        <CardWrapper>
          <Input
            name="ai_characteristic_description"
            label={<FormattedMessage id="Characteristic" />}
            normalFont={true}
            {...form.bindInput('ai_characteristic_description')}
          />
        </CardWrapper>

        <PositionWrapper center={true}>
          <RowWrapper gap="1em">
            <ToggleSwitch
              label={<FormattedMessage id="Project private" />}
              {...form.bindCheckbox('private')}
            />
            <span aria-hidden="true">
              <FormattedMessage id="Project private" />
            </span>
          </RowWrapper>
        </PositionWrapper>

        <PositionWrapper center={true}>
          <MainButton type="submit">
            <FormattedMessage id="Create project" />
          </MainButton>
        </PositionWrapper>
      </ColumnWrapper>
    </Form>
  );
}

import classNames from 'classnames';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import CloseButton from 'components/CloseButton';
import Headline from 'components/headline';
import Cluster from 'components/Layout/Cluster';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import AssistantBenchmarkContainer from 'screens/assistant/AssistantBenchmarkContainer';
import GapSizes from 'utils/constants/gap-sizes';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';
import AssistantSidebar, {
  AssistantSidebarProps
} from './AssistantSidebar/AssistantSidebar';
import { SidebarMarkerType } from './AssistantSidebar/SidebarMarker';
import { SessionSidebarStep } from './session-elements';
import SessionTimer from './SessionTimer';
import PainpointDetailContainer from 'containers/PainpointDetailContainer';

interface PointNavItem {
  to: string;
  active?: boolean;
}

export type PointNav = PointNavItem[];

interface StepContainerProps {
  sidebarHeadline?: string | React.ReactNode;
  sidebarContent?: React.ReactNode;
  pointNav?: PointNav;
  progress?: number;
  onCloseClick?: () => void;
  noDefaultCloseButton?: boolean;
  timerSeconds?: number;
  showHeader?: AssistantSidebarProps['showHeader'];
  noPadding?: boolean;
  bottomSpace?: boolean;
  displayBenchmarkId?: number;
  displayPainpointId?: number;
  canGoBack?: boolean;
  sessionId?: number;
  currentPath?: string;
  currentPathWithSearch?: string;
  top?: any;
  children?: any;
  minHeightZero?: boolean;
}

export const StepContainerWithoutLocation = ({
  progress,
  sidebarHeadline,
  sidebarContent,
  onCloseClick,
  pointNav,
  noDefaultCloseButton,
  timerSeconds,
  showHeader,
  noPadding,
  bottomSpace,
  displayBenchmarkId,
  displayPainpointId,
  canGoBack,
  sessionId,
  currentPath,
  currentPathWithSearch,
  top,
  children,
  minHeightZero
}: StepContainerProps) => {
  let component;
  if (displayBenchmarkId || displayPainpointId) {
    // onCloseClick action for benchmark view
    noPadding = true;
    onCloseClick = () => {
      if (canGoBack) {
        history.goBack();
      } else {
        history.push(ROUTE_ASSISTANT + (sessionId ? '/' + sessionId : ''));
      }
    };

    if (displayBenchmarkId) {
      component = (
        <AssistantBenchmarkContainer benchmarkId={displayBenchmarkId} />
      );
    } else if (displayPainpointId) {
      component = (
        <PainpointDetailContainer
          painpointId={displayPainpointId}
          hideClose={true}
        />
      );
    }
  } else {
    if (!onCloseClick && !noDefaultCloseButton) {
      // default onCloseClick action
      onCloseClick = () => {
        if (canGoBack) {
          history.goBack();
        } else {
          history.push(ROUTE_ASSISTANT);
        }
      };
    }
  }

  return (
    <Columns columnTemplate="1fr 22.1875rem" gap={GapSizes.NONE}>
      <Column>
        <div
          className={classNames('session', {
            'session--no-padding': !!noPadding,
            'session--extra-bottom-space': !!bottomSpace,
            'session--min-height-zero': minHeightZero
          })}
        >
          <div className="session__fixed">
            <Cluster justification="flex-end">
              {top}

              {timerSeconds && <SessionTimer seconds={timerSeconds} />}

              {onCloseClick ? (
                <CloseButton
                  label="schließen"
                  iconName="cross"
                  onClick={onCloseClick}
                />
              ) : null}
            </Cluster>
          </div>

          <div
            className="progress"
            style={{ '--progress-indicator': progress || 0 } as CSSProperties}
          />

          {!!pointNav?.length && (
            <nav className="point-navigation">
              <ul>
                {pointNav.map((item, idx) => (
                  <li key={idx}>
                    <Link
                      to={item.to}
                      className={item.active ? 'active' : undefined}
                    >
                      <span className="sr-only">Step {idx + 1}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}

          {component || children}
        </div>
      </Column>

      <Column>
        <div className="session-sidebar">
          <div
            className="session-sidebar__scroll-container"
            id="assistantScrollContainer"
          >
            {sidebarHeadline && (
              <div className="session-sidebar__header">
                <Headline tag="h2" size="M" opaque={true}>
                  <SessionSidebarStep step={sidebarHeadline} />
                </Headline>
              </div>
            )}

            {sidebarContent ? (
              sidebarContent
            ) : (
              <AssistantSidebar
                showHeader={showHeader}
                currentPath={currentPath}
                currentPathWithSearch={currentPathWithSearch}
              />
            )}
          </div>
        </div>
      </Column>
    </Columns>
  );
};

export const StepContainer = (
  props: StepContainerProps & { location?: HistoryProps['location'] }
) => {
  const benchmarkId = props.location?.query?.benchmark;
  const painpointId = props.location?.query?.painpoint;

  return (
    <StepContainerWithoutLocation
      {...props}
      displayBenchmarkId={benchmarkId}
      displayPainpointId={painpointId}
      canGoBack={props.location?.state?.canGoBack}
      currentPath={props.location?.pathname}
      currentPathWithSearch={
        props.location?.pathname + (props.location?.search || '')
      }
    />
  );
};

export const scrollSidebar = (position?: SidebarMarkerType) => {
  setTimeout(() => {
    const container = document.querySelector('#assistantScrollContainer');
    const elementKey =
      '#assistantScrollContainer_' + (!position ? 'end' : position);

    const elem = document.querySelector(elementKey) as HTMLElement;

    if (!container || !elem || typeof elem.offsetTop !== 'number') {
      return;
    }

    const headerHeight =
      document.querySelector('.session-sidebar__header')?.clientHeight || 0;

    container.scrollTo({
      top: elem.offsetTop - headerHeight,
      behavior: 'smooth'
    });
  }, 250);
};

export default StepContainer;

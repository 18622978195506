import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ActionOverlayButton from 'components/ActionOverlayButton/ActionOverlayButton';
import BenchmarksButton from 'components/ActionOverlayButton/BenchmarksButton';
import HypothesisButton from 'components/ActionOverlayButton/HypothesisButton';
import PrototypesButton from 'components/ActionOverlayButton/PrototypesButton';
import BenchmarkSelectPopupItem from 'components/BenchmarkSelectPopup/BenchmarkSelectPopupItem';
import DestroyButton from 'components/DestroyButton';
import { FlashMessageDisplayComponent } from 'components/FlashMessage/FlashMessage';
import Form from 'components/Form';
import HypothesisSelectPopupItem from 'components/HypothesisSelectPopup/HypothesisSelectPopupItem';
import Input from 'components/Inputs/Input';
import AutoGrid from 'components/Layout/AutoGrid';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import Loading from 'components/Loading';
import PainpointSelectPopupItem from 'components/PainpointSelectPopup/PainpointSelectPopupItem';
import PrototypeSelectPopupItem from 'components/PrototypeSelectPopup/PrototypeSelectPopupItem';
import SelectCounter from 'components/SelectCounter';
import SelectMenu from 'components/SelectMenu';
import SelectTop from 'components/SelectTop';
import SimplePopup from 'components/SimplePopup';
import ToggleSwitch from 'components/ToggleSwitch';
import CopyToProjectContainer from 'containers/CopyToProjectContainer';
import {
  ActionsStoreType,
  elementMapFilter,
  elementMapToArray,
  elementMapToMatrix,
  ElementWithContextModelType,
  SelectableElementType,
  TypedElementWithContextMap
} from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { DataStoreType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { PrototypeModelType } from 'models/PrototypeModel';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';

enum ShareTab {
  download = 'download',
  sharelink = 'sharelink',
  powerpoint = 'powerpoint'
}
enum Overlay {
  copy = 'copy',
  share = 'share',
  selectDelete = 'selectDelete',
  settings = 'settings'
}

interface PublicActionsOverlayContainerProps extends WrappedComponentProps {
  copyInitiallySelectedOrganizationId?: number;
  copyInitiallySelectedProjectId?: number;
  shareInitiallyActiveTab?: ShareTab;
  showCopy?: boolean;
  showDelete?: boolean;
  showShare?: boolean;
  showAiBenchmark?: boolean;
  showAiBriefing?: boolean;
}

interface ActionsOverlayContainerProps
  extends PublicActionsOverlayContainerProps {
  actionsStore: ActionsStoreType;
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  projectsStore: ProjectsStoreType;
  form: FormType;
}

interface ActionsOverlayContainerState {
  activeOverlay?: Overlay;
  activeSharingTab?: ShareTab;
  isLoading: boolean;
  isSharingPasswordProtected: boolean;
  sharingLink?: string;
  wasCopySuccessful: boolean;
  selectedElements: {
    [key: string]: Array<{
      item: ElementWithContextModelType;
      checked: boolean;
    }>;
  };
}

@inject(
  'actionsStore',
  'applicationStore',
  'dataStore',
  'painpointsStore',
  'projectsStore'
)
@observer
class ActionsOverlayContainer extends React.Component<
  ActionsOverlayContainerProps,
  ActionsOverlayContainerState
> {
  state: ActionsOverlayContainerState = {
    activeOverlay: undefined,
    activeSharingTab: ShareTab.powerpoint,
    isLoading: false,
    isSharingPasswordProtected: false,
    sharingLink: undefined,
    wasCopySuccessful: false,
    selectedElements: {
      Painpoint: [],
      Benchmark: [],
      Hypothesis: [],
      Prototype: [],
      Learning: []
    }
  };

  mapStoreValues(values: Iterable<ElementWithContextModelType>) {
    return Array.from(values).map((item) => ({
      item,
      checked: true
    }));
  }

  componentDidUpdate(): void {
    const { actionsStore } = this.props;
    if (
      actionsStore &&
      (actionsStore.painpoints.size !==
        this.state.selectedElements.Painpoint.length ||
        actionsStore.benchmarks.size !==
          this.state.selectedElements.Benchmark.length ||
        actionsStore.hypotheses.size !==
          this.state.selectedElements.Hypothesis.length ||
        actionsStore.prototypes.size !==
          this.state.selectedElements.Prototype.length)
    ) {
      this.setState({
        selectedElements: {
          Painpoint: this.mapStoreValues(actionsStore!.painpoints.values()),
          Benchmark: this.mapStoreValues(actionsStore!.benchmarks.values()),
          Hypothesis: this.mapStoreValues(actionsStore!.hypotheses.values()),
          Prototype: this.mapStoreValues(actionsStore!.prototypes.values()),
          Learning: []
        }
      });
    }
  }

  getShareableElements(): Partial<TypedElementWithContextMap> {
    const { currentOrganizationId } = this.props.dataStore;
    return elementMapFilter(
      this.props.actionsStore.selectedElements,
      (element) =>
        element.organizationId === currentOrganizationId &&
        element.publishState !== 'draft'
    );
  }

  overlayOpen(overlay: Overlay) {
    this.setState({
      activeOverlay: overlay,
      // If at least one of the selected elements can be shared via link, show share link tab by default
      activeSharingTab:
        elementMapToArray(this.getShareableElements()).length > 0
          ? ShareTab.powerpoint
          : ShareTab.download
    });
  }

  overlayClose() {
    this.props.form.values.sharePassword = '';
    this.props.form.values.shareHasPassword = false;
    this.setState({
      activeOverlay: undefined,
      activeSharingTab: ShareTab.powerpoint,
      isLoading: false,
      isSharingPasswordProtected: false,
      sharingLink: undefined
    });
  }

  async copySharingLink() {
    if (!navigator.clipboard || !this.state.sharingLink) {
      return;
    }

    const { applicationStore, intl } = this.props;

    try {
      await navigator.clipboard.writeText(this.state.sharingLink);
      this.setState({ wasCopySuccessful: true });
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'copy link error' }),
        'error'
      );
    }
  }

  async deleteElements(): Promise<number> {
    const { actionsStore, intl } = this.props;

    Object.entries(this.state.selectedElements).forEach(
      ([elementType, elements]) => {
        elements.forEach(
          (item: { item: ElementWithContextModelType; checked: boolean }) => {
            if (!item.checked) {
              actionsStore!.selectionSet(
                {
                  [elementType]: [{ ...item.item }]
                },
                false
              );
            }
          }
        );
      }
    );

    // Abort if elements from different projects, or no elements at all are selected
    if (
      actionsStore.selectedCount() < 1 ||
      actionsStore.selectedFromProjectIds.length > 1
    ) {
      return 0;
    }

    const typesForMessageId: { [k in SelectableElementType]: string } = {
      Benchmark: 'benchmarks',
      Hypothesis: 'hypotheses',
      Painpoint: 'painpoints',
      Prototype: 'prototypes',
      Learning: 'learnings'
    };

    const { selectedFromTypes } = actionsStore;
    const typeInMessageId =
      selectedFromTypes.length > 1
        ? 'elements'
        : typesForMessageId[selectedFromTypes[0]];

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: `remove ${typeInMessageId} confirm` },
          { count: String(actionsStore.selectedCount()) }
        )
      )
    ) {
      return 0;
    }

    return await actionsStore.deleteElements(actionsStore.selectedIds);
  }

  async createSharingLink() {
    const { actionsStore, form } = this.props;
    const password = form.values.shareHasPassword
      ? form.values.sharePassword
      : '';

    this.setState({ isLoading: true });
    const sharingLink = await actionsStore.createSharingLink(
      password,
      actionsStore.selectedElements
    );

    if (!isDefined(sharingLink)) {
      // Sharing link could not be created
      this.setState({ isLoading: false });
      this.overlayClose();
      return;
    }

    this.setState({
      isLoading: false,
      isSharingPasswordProtected: sharingLink.requires_password,
      sharingLink: sharingLink.url
    });
  }

  async downloadElements() {
    const { actionsStore } = this.props;

    this.setState({ isLoading: true });
    await actionsStore.downloadPdf(actionsStore.selectedIds);
    this.setState({ isLoading: false });
    this.overlayClose();
  }

  async downloadPowerpoint() {
    const { actionsStore, dataStore } = this.props;
    const project = dataStore.currentProject;

    if (!project) {
      return;
    }

    this.setState({ isLoading: true });

    await actionsStore.downloadPowerpoint(project.id, actionsStore.selectedIds);
    this.setState({ isLoading: false });
    this.overlayClose();
  }

  handleSetSelection(
    elementType: SelectableElementType,
    element: ElementWithContextModelType
  ) {
    this.setState({
      selectedElements: {
        ...this.state.selectedElements,
        [elementType]: this.state.selectedElements[elementType].map((item) => {
          if (item.item.id === element.id) {
            return {
              ...item,
              checked: !item.checked
            };
          }
          return item;
        })
      }
    });
  }

  render() {
    const {
      actionsStore,
      copyInitiallySelectedOrganizationId,
      copyInitiallySelectedProjectId,
      dataStore,
      form,
      intl,
      applicationStore,
      showAiBenchmark
    } = this.props;

    const { activeOverlay } = this.state;
    const { project } = dataStore;

    const showCreateHypotheses =
      actionsStore.selectedIds.Painpoint.length > 0 &&
      actionsStore.selectedIds.Benchmark.length > 0 &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const showCreateBenchmarks =
      showAiBenchmark &&
      (actionsStore.selectedIds.Painpoint.length > 0 ||
        actionsStore.selectedIds.Benchmark.length > 0) &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const showCreatePrototypes =
      actionsStore.selectedIds.Hypothesis.length > 0 &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const trueCount = [
      showCreateHypotheses,
      showCreateBenchmarks,
      showCreatePrototypes
    ].filter(Boolean).length;

    const fourColumns = trueCount === 2;
    const fiveColumns = trueCount === 3;

    const shareCountsForMessage = Object.entries(this.getShareableElements())
      .map(([elementType, elements]) => [
        elementType,
        isDefined(elements) ? elements.length : 0
      ])
      .reduce(
        (map, [elementType, count]) =>
          Object.assign(map, {
            [elementType]: count,
            total: map.total + Number(count)
          }),
        { Benchmark: 0, Hypothesis: 0, Prototype: 0, Learning: 0, total: 0 }
      );

    return (
      <>
        {activeOverlay === Overlay.selectDelete && (
          <SimplePopup
            onAbort={() => this.overlayClose()}
            onSubmit={() => this.deleteElements()}
            headlineTextId="selected share items"
            submitTextId="Remove Now"
          >
            {actionsStore!.selectedCount() > 0 && (
              <SelectCounter value={actionsStore!.selectedCount()} />
            )}

            <div className="simple-popup__accent-body simple-popup__scrollable-body">
              <AutoGrid>
                {this.state.selectedElements.Painpoint.map(
                  (painpointElement) => (
                    <PainpointSelectPopupItem
                      onClick={() => {}}
                      onChange={() => {
                        this.handleSetSelection(
                          ElementType.Painpoint,
                          painpointElement.item
                        );
                      }}
                      painpoint={
                        dataStore!.painpoints?.get(
                          painpointElement.item.id.toString()
                        ) as PainpointModelType
                      }
                      selected={painpointElement.checked}
                      key={painpointElement.item.id}
                    />
                  )
                )}
                {this.state.selectedElements.Benchmark.map((benchmarkItem) => (
                  <BenchmarkSelectPopupItem
                    onClick={() =>
                      this.handleSetSelection(
                        ElementType.Benchmark,
                        benchmarkItem.item
                      )
                    }
                    benchmark={
                      dataStore!.benchmarks?.get(
                        benchmarkItem.item.id.toString()
                      ) as BenchmarkModelType
                    }
                    selected={benchmarkItem.checked}
                    key={benchmarkItem.item.id}
                  />
                ))}
                {this.state.selectedElements.Hypothesis.map(
                  (hypothesesItem) => (
                    <HypothesisSelectPopupItem
                      onClick={() =>
                        this.handleSetSelection(
                          ElementType.Hypothesis,
                          hypothesesItem.item
                        )
                      }
                      hypothesis={
                        dataStore!.hypotheses?.get(
                          hypothesesItem.item.id.toString()
                        ) as HypothesisModelType
                      }
                      selected={hypothesesItem.checked}
                      key={hypothesesItem.item.id}
                    />
                  )
                )}
                {this.state.selectedElements.Prototype.map((prototypeItem) => (
                  <PrototypeSelectPopupItem
                    onClick={() =>
                      this.handleSetSelection(
                        ElementType.Prototype,
                        prototypeItem.item
                      )
                    }
                    prototype={
                      dataStore!.prototypes?.get(
                        prototypeItem.item.id.toString()
                      ) as PrototypeModelType
                    }
                    selected={prototypeItem.checked}
                    key={prototypeItem.item.id}
                  />
                ))}
              </AutoGrid>
            </div>
          </SimplePopup>
        )}

        {activeOverlay === Overlay.copy && (
          <CopyToProjectContainer
            elements={elementMapToMatrix(actionsStore.selectedIds)}
            forceNoCurrent={true}
            initiallySelectedOrganizationId={
              copyInitiallySelectedOrganizationId
            }
            initiallySelectedProjectId={copyInitiallySelectedProjectId}
            onAbort={() => this.overlayClose()}
            onFinish={() => {
              this.overlayClose();
              actionsStore.selectionClear();
            }}
          />
        )}

        {activeOverlay === Overlay.share && (
          <OverlayWrapper
            disabled={true}
            onExit={() => this.overlayClose()}
            background="dark"
          >
            <article className="share-popup">
              <header className="share-popup__header">
                <h1 className="share-popup__headline">
                  <FormattedMessage id="Share selected content" />
                </h1>
                {!isDefined(this.state.sharingLink) &&
                  !this.state.isLoading && (
                    <ul className="share-popup__tabs">
                      {[
                        { tab: ShareTab.powerpoint, label: 'Powerpoint' },
                        { tab: ShareTab.sharelink, label: 'URL' },
                        { tab: ShareTab.download, label: 'PDF' }
                      ].map(({ tab, label }) => (
                        <li key={tab}>
                          <button
                            className={`share-popup__tab-link${
                              this.state.activeSharingTab === tab
                                ? ' share-popup__tab-link--active'
                                : ''
                            }`}
                            onClick={() =>
                              this.setState({ activeSharingTab: tab })
                            }
                          >
                            <FormattedMessage id={label} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
              </header>

              <div
                className="share-popup__body share-popup__body--centered"
                style={{ '--min-height': '19em' } as React.CSSProperties}
              >
                {this.state.activeSharingTab === ShareTab.powerpoint &&
                  (this.state.isLoading ? (
                    <div
                      className="l-row-wrapper l-row-wrapper--center"
                      style={{ 'min-height': '19em' } as React.CSSProperties}
                    >
                      <Loading inline={true} />
                    </div>
                  ) : (
                    // Download-popup content
                    <>
                      <div className="share-popup__description text-center">
                        <FormattedMessage id="Share export as Powerpoint" />
                      </div>

                      <div className="share-popup__icon">
                        <img src="/images/powerpoint-logo.png" alt="" />
                      </div>

                      <button
                        className="main-button"
                        onClick={() => this.downloadPowerpoint()}
                      >
                        <FormattedMessage id="Download PPTX" />
                      </button>
                    </>
                  ))}

                {this.state.activeSharingTab === ShareTab.sharelink &&
                  (elementMapToArray(this.getShareableElements()).length < 1 ? (
                    // No shareable elements selected
                    <ColumnWrapper>
                      <div className="share-popup__info">
                        <FormattedMessage id="Share link no selected elements shareable" />
                      </div>
                    </ColumnWrapper>
                  ) : this.state.isLoading ? (
                    <div
                      className="l-row-wrapper l-row-wrapper--center"
                      style={{ 'min-height': '19em' } as React.CSSProperties}
                    >
                      <Loading inline={true} />
                    </div>
                  ) : (
                    // Share-link-popup content
                    <>
                      {(!isDefined(this.state.sharingLink) ||
                        this.state.isSharingPasswordProtected) && (
                        <>
                          <div
                            className="l-row-wrapper l-row-wrapper--spaceBetween l-row-wrapper--separator"
                            style={{ width: '100%' } as React.CSSProperties}
                          >
                            <div className="share-popup__description">
                              <FormattedMessage id="Share password protection" />
                              :
                            </div>
                            {isDefined(this.state.sharingLink) || (
                              <ToggleSwitch
                                id="share-has-password"
                                disabled={
                                  isDefined(this.state.sharingLink) ||
                                  this.state.isLoading
                                }
                                label={intl.formatMessage({
                                  id: 'Share password protection'
                                })}
                                {...form.bindCheckbox('shareHasPassword')}
                              />
                            )}
                          </div>

                          {form.values.shareHasPassword && (
                            <ColumnWrapper>
                              <Input
                                name="share-password"
                                readOnly={
                                  isDefined(this.state.sharingLink) ||
                                  this.state.isLoading
                                }
                                placeholder={intl.formatMessage({
                                  id: 'Share password placeholder'
                                })}
                                {...form.bindInput('sharePassword')}
                              />
                            </ColumnWrapper>
                          )}
                        </>
                      )}

                      {!isDefined(this.state.sharingLink) ? ( // Before link is created
                        <>
                          <ColumnWrapper>
                            {elementMapToArray(this.getShareableElements())
                              .length < actionsStore.selectedCount() && (
                              <>
                                <div className="share-popup__info">
                                  <FormattedMessage
                                    id="Share create link counts"
                                    values={shareCountsForMessage}
                                  />
                                </div>

                                {actionsStore.selectedCount() -
                                  actionsStore.selectedCount(
                                    ElementType.Painpoint
                                  ) >
                                  elementMapToArray(this.getShareableElements())
                                    .length && (
                                  <div className="share-popup__warning">
                                    <FormattedMessage id="Share link cannot share from network and drafts" />
                                  </div>
                                )}

                                {actionsStore.selectedCount(
                                  ElementType.Painpoint
                                ) > 0 && (
                                  <div className="share-popup__warning">
                                    <FormattedMessage id="Share link cannot share painpoints" />
                                  </div>
                                )}
                              </>
                            )}

                            <div className="share-popup__description text-center">
                              <FormattedMessage id="Share generate private URL" />
                            </div>

                            {elementMapToArray(this.getShareableElements())
                              .length === actionsStore.selectedCount() && (
                              /* Only show icon if no warnings are shown, so the popup does not grow vertically */
                              <div className="share-popup__icon">
                                <img src="/images/url-icon.png" alt="" />
                              </div>
                            )}
                          </ColumnWrapper>

                          <button
                            className="main-button"
                            onClick={() => this.createSharingLink()}
                          >
                            <FormattedMessage id="Create sharing link" />
                          </button>
                        </>
                      ) : (
                        // After link is created
                        <ColumnWrapper>
                          <div className="share-popup__info share-popup__info--space-before">
                            <FormattedMessage id="Share copy URL info" />
                            {/* TODO Add info icon with additional text on hover/tap */}
                          </div>
                          <Form onSubmit={() => this.copySharingLink()}>
                            <Input
                              button={intl.formatMessage({
                                id: this.state.wasCopySuccessful
                                  ? 'copy link success'
                                  : 'copy link'
                              })}
                              name="share-link-url"
                              readOnly={true}
                              showSuccess={this.state.wasCopySuccessful}
                              value={this.state.sharingLink}
                            />
                          </Form>
                        </ColumnWrapper>
                      )}
                    </>
                  ))}

                {this.state.activeSharingTab === ShareTab.download &&
                  (this.state.isLoading ? (
                    <div
                      className="l-row-wrapper l-row-wrapper--center"
                      style={{ 'min-height': '19em' } as React.CSSProperties}
                    >
                      <Loading inline={true} />
                    </div>
                  ) : (
                    // Download-popup content
                    <>
                      <div className="share-popup__description text-center">
                        <FormattedMessage id="Share export as PDF" />
                      </div>

                      <div className="share-popup__icon">
                        <img src="/images/pdf-icon.png" alt="" />
                      </div>

                      <button
                        className="main-button"
                        onClick={() => this.downloadElements()}
                      >
                        <FormattedMessage id="Download PDF" />
                      </button>
                    </>
                  ))}
              </div>

              <DestroyButton onClick={() => this.overlayClose()} />
            </article>
          </OverlayWrapper>
        )}

        {actionsStore.selectedCount() > 0 && !isDefined(activeOverlay) && (
          <OverlayWrapper top={true}>
            <div className="select-menu--wrapper">
              <FlashMessageDisplayComponent
                message={
                  applicationStore!.flashMessageType === 'inlineError'
                    ? applicationStore.flashMessage
                    : undefined
                }
                type="error"
                hidden={applicationStore!.flashMessageHidden}
                onHide={() => applicationStore!.hideFlashMessage()}
              />
              <SelectMenu fourColumns={fourColumns} fiveColumns={fiveColumns}>
                <SelectTop />
                <div className="select-menu__buttons">
                  {this.props.showCopy &&
                    dataStore.currentOrganization?.access_level !==
                      'viewer' && (
                      <ActionOverlayButton
                        label={intl.formatMessage({ id: 'copy over' })}
                        iconName="arrow-right"
                        onClick={() => this.overlayOpen(Overlay.copy)}
                      />
                    )}
                  <div className="stack-m">
                    {this.props.showDelete && dataStore.isOrgAdmin && (
                      <ActionOverlayButton
                        inline={true}
                        label={intl.formatMessage({ id: 'Remove Now' })}
                        iconName="bin"
                        onClick={() => this.overlayOpen(Overlay.selectDelete)}
                      />
                    )}

                    {this.props.showShare && (
                      <ActionOverlayButton
                        inline={true}
                        label={intl.formatMessage({ id: 'Sharing' })}
                        iconName="clipboard"
                        onClick={() => this.overlayOpen(Overlay.share)}
                      />
                    )}
                  </div>
                  {showCreateBenchmarks && project?.has_user_settings  ? (
                    <BenchmarksButton />
                  ) : (
                    <ActionOverlayButton
                      label={intl.formatMessage({ id: 'Search benchmarks' })}
                      iconName="ai"
                      onClick={() => this.overlayOpen(Overlay.settings)}
                    />
                  )}
                  {showCreateHypotheses && <HypothesisButton />}
                  {showCreatePrototypes && <PrototypesButton />}
                </div>
              </SelectMenu>
            </div>
          </OverlayWrapper>
        )}
      </>
    );
  }
}

export default injectIntl((props: PublicActionsOverlayContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <ActionsOverlayContainer {...props} form={form} />;
});

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { useEffect, useState } from 'react';
import CardWrapper from 'components/CardWrapper';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import { FormattedMessage, useIntl } from 'react-intl';
import MainButton from 'components/MainButton';
import { SessionFixed } from 'domain/assistant/session-elements';
import { useStores } from 'index';

export default function TopicAndChallengeScreen() {
  const match = useRouteMatch<{ step: string }>();
  const history = useHistory();
  const { actionsStore, dataStore } = useStores();
  const {
    setCurrentStep,
    projectTopic,
    setProjectTopic,
    projectChallenge,
    setProjectChallenge,
    currentStep
  } = useCreateProject();
  const intl = useIntl();

  const [tmpTopic, setTmpTopic] = useState(projectTopic || '');
  const [tmpChallenge, setTmpChallenge] = useState(projectChallenge || '');
  const [topicError, setTopicError] = useState(false);
  useEffect(() => {
    if (match.params.step !== currentStep.toString()) {
      setCurrentStep(Number(match.params.step));
    }
  }, [match.params.step]);

  return (
    <div className="l-item-stack-regular" style={{ marginBottom: '6rem' }}>
      <CardWrapper>
        <Input
          name="topic"
          label={intl.formatMessage({
            id: 'create project topic and challenge topic label'
          })}
          placeholder={intl.formatMessage({
            id: 'create project topic and challenge topic placeholder'
          })}
          autoFocus={true}
          normalFont={true}
          value={tmpTopic}
          onChange={(e) => setTmpTopic(e.target.value)}
          error={
            topicError ? (
              <FormattedMessage id="Project topic taken" />
            ) : undefined
          }
          onBlur={async () => {
            const { available } = await actionsStore.checkTopicUniqueness(
              dataStore.organization!.id,
              tmpTopic
            );
            if (!available) {
              setTopicError(true);
            } else if (topicError && available) {
              setTopicError(false);
            }
          }}
        />
      </CardWrapper>

      <CardWrapper>
        <Textarea
          label={intl.formatMessage({
            id: 'create project topic and challenge challenge label'
          })}
          lines={16}
          largeFont={true}
          onChange={(e) => setTmpChallenge(e.target.value)}
          placeholder={intl.formatMessage({
            id: 'create project topic and challenge challenge placeholder'
          })}
          name="question"
          normalFont={true}
          value={tmpChallenge}
        />
      </CardWrapper>

      <SessionFixed>
        <MainButton
          secondary={true}
          onClick={() => {
            history.goBack();
            setCurrentStep(currentStep - 1);
          }}
        >
          <FormattedMessage id="Back" />
        </MainButton>
        <MainButton
          onClick={() => {
            setProjectTopic(tmpTopic);
            setProjectChallenge(tmpChallenge);
            setCurrentStep(currentStep + 1);
          }}
          disabled={!tmpTopic || !tmpChallenge}
        >
          <FormattedMessage id="Next" />
        </MainButton>
      </SessionFixed>
    </div>
  );
}

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { useEffect, useState } from 'react';
import CardWrapper from 'components/CardWrapper';
import Textarea from 'components/Inputs/Textarea';
import { FormattedMessage, useIntl } from 'react-intl';
import MainButton from 'components/MainButton';
import { useStores } from 'index';
import Select from 'components/Inputs/Select';
import { observer } from 'mobx-react';
import OrSeperator from 'components/OrSeperator';
import InnerLinkHelper from 'screens/ProjectsListScreen/InnerLinkHelper';
import { ROUTE_LAB } from 'utils/constants/routes';

function ServiceScreen() {
  const match = useRouteMatch<{ step: string }>();
  const history = useHistory();
  const intl = useIntl();
  const { organizationsStore, dataStore } = useStores();

  const { setCurrentStep, projectService, setProjectService, currentStep } =
    useCreateProject();

  useEffect(() => {
    organizationsStore.getServices();
  }, []);

  useEffect(() => {
    if (match.params.step !== currentStep.toString()) {
      setCurrentStep(Number(match.params.step));
    }
  }, [match.params.step]);

  const [tmpService, setTmpService] = useState(projectService || '');

  return (
    <div className="l-item-stack-regular">
      <CardWrapper>
        <InnerLinkHelper
          href={
            ROUTE_LAB +
            '/' +
            dataStore.currentOrganizationId +
            '/settings/services'
          }
          label={intl.formatMessage({ id: 'Manage services' })}
        />
        <Select
          name="service"
          label={<FormattedMessage id="Service" />}
          normalFont={true}
          onFocus={() => {
            organizationsStore.getServices();
          }}
          onChange={(e) => setTmpService(e.target.value)}
        >
          <option value="">
            <FormattedMessage
              id={
                dataStore.orgServices.size === 0
                  ? 'No services'
                  : 'Select service'
              }
            />
          </option>
          {Array.from(dataStore.orgServices.values()).map((service) => (
            <option key={service.id} value={service.content!}>
              {service.name}
            </option>
          ))}
        </Select>
      </CardWrapper>
      <OrSeperator small={true} />
      <CardWrapper>
        <Textarea
          lines={6}
          largeFont={true}
          onChange={(e) => setTmpService(e.target.value)}
          placeholder={intl.formatMessage({
            id: 'create project service placeholder'
          })}
          name="question"
          normalFont={true}
          value={tmpService}
        />
      </CardWrapper>
      <div className="l-item-stack-horizontal l-item-stack-horizontal--flex-end">
        <MainButton
          secondary={true}
          onClick={() => {
            setCurrentStep(currentStep - 1);
            history.goBack();
          }}
        >
          <FormattedMessage id="Back" />
        </MainButton>
        <MainButton
          onClick={() => {
            setProjectService(tmpService);
            setCurrentStep(currentStep + 1);
          }}
          disabled={!tmpService}
        >
          <FormattedMessage id="Next" />
        </MainButton>
      </div>
    </div>
  );
}

export default observer(ServiceScreen);

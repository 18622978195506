import 'core-js/stable';
import 'regenerator-runtime/runtime';
// tslint:disable-next-line: ordered-imports
import 'react-app-polyfill/ie11';

import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client';
import { RawIntlProvider } from 'react-intl';

import bootstrap from 'config/bootstrap/bootstrap';
import AppRouter from 'routers/AppRouter';
import { createContext, useContext } from 'react';
import { ActionsStoreType } from 'models/ActionsStore';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
// Define the type for your stores
interface Stores {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  projectsStore: ProjectsStoreType;
  organizationsStore: OrganizationsStoreType;
  actionsStore: ActionsStoreType;
}

// Create the context with a default empty object
export const StoresContext = createContext<Stores | null>(null);

// Create a custom hook for easy access
export const useStores = (): Stores => {
  const context = useContext(StoresContext);
  if (!context) {
    throw new Error('useStores must be used within a StoresContext.Provider');
  }
  return context;
};

bootstrap(({ stores, intl }) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <RawIntlProvider value={intl}>
      <Provider {...stores}>
        <StoresContext.Provider value={stores}>
          <AppRouter />
        </StoresContext.Provider>
      </Provider>
    </RawIntlProvider>
  );
});

export const SidebarItem = ({
  children,
  onClick
}: {
  children?: any;
  onClick?: () => void;
}) => (
  <div className="sidebar-teaser" onClick={onClick}>
    {children}
  </div>
);

export default SidebarItem;

import React, { createContext, useContext, useState } from 'react';
import { StrategyType } from 'components/ProjectType/ProjectType';
import { useStores } from 'index';

interface CreateProjectContextType {
  selectedStrategyType: StrategyType | undefined;
  setSelectedStrategyType: (type: StrategyType) => void;
  guided: boolean;
  setGuided: (guided: boolean) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  orgId: number | undefined;
  projectTopic: string;
  setProjectTopic: (topic: string) => void;
  projectChallenge: string;
  setProjectChallenge: (challenge: string) => void;
  projectTargetGroup: string;
  setProjectTargetGroup: (targetGroup: string) => void;
  projectService: string;
  setProjectService: (service: string) => void;
  projectBusinessTarget: string;
  setProjectBusinessTarget: (businessTarget: string) => void;
  projectBusinessTargetFormat: string;
  setProjectBusinessTargetFormat: (businessTargetFormat: string) => void;
  setSubmitted: (submitted: boolean) => void;
  questions: string[];
  setQuestions: (questions: string[]) => void;
  selectedQuestion: string | undefined;
  setSelectedQuestion: (question: string) => void;
  submitted: boolean;
}

const CreateProjectContext = createContext<
  CreateProjectContextType | undefined
>(undefined);

interface CreateProjectProviderProps {
  children: React.ReactNode;
}

export function CreateProjectProvider({
  children
}: CreateProjectProviderProps) {
  const { dataStore } = useStores();
  const org = dataStore.currentOrganization;

  const [selectedStrategyType, setSelectedStrategyType] =
    useState<StrategyType>();
  const [guided, setGuided] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectTopic, setProjectTopic] = useState('');
  const [projectChallenge, setProjectChallenge] = useState('');
  const [projectTargetGroup, setProjectTargetGroup] = useState('');
  const [projectService, setProjectService] = useState('');
  const [projectBusinessTarget, setProjectBusinessTarget] = useState('');
  const [projectBusinessTargetFormat, setProjectBusinessTargetFormat] =
    useState('');
  const [questions, setQuestions] = useState<string[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  return (
    <CreateProjectContext.Provider
      value={{
        selectedStrategyType,
        setSelectedStrategyType,
        guided,
        setGuided,
        currentStep,
        setCurrentStep,
        orgId: org?.id,
        projectTopic,
        setProjectTopic,
        projectChallenge,
        setProjectChallenge,
        projectTargetGroup,
        setProjectTargetGroup,
        projectService,
        setProjectService,
        projectBusinessTarget,
        setProjectBusinessTarget,
        projectBusinessTargetFormat,
        setProjectBusinessTargetFormat,
        setSubmitted,
        questions,
        setQuestions,
        selectedQuestion,
        setSelectedQuestion,
        submitted
      }}
    >
      {children}
    </CreateProjectContext.Provider>
  );
}

export const useCreateProject = () => {
  const context = useContext(CreateProjectContext);
  if (context === undefined) {
    throw new Error(
      'useCreateProject must be used within a CreateProjectProvider'
    );
  }
  return context;
};

import CardHeader from 'components/CardHeader';
import CardWrapper from 'components/CardWrapper';
import Headline from 'components/headline';
import Flow from 'components/Layout/Flow';
import MainButton from 'components/MainButton';
import RainbowCard from 'components/RainbowCard/RainbowCard';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { SessionFixed } from 'domain/assistant/session-elements';
import { useStores } from 'index';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTE_PROJECT } from 'utils/constants/routes';

function SelectQuestionScreen() {
  const match = useRouteMatch<{ step: string }>();
  const { applicationStore, projectsStore, dataStore } = useStores();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    setCurrentStep,
    currentStep,
    setSubmitted,
    submitted,
    projectTopic,
    projectChallenge,
    projectTargetGroup,
    projectService,
    projectBusinessTarget,
    projectBusinessTargetFormat,
    selectedStrategyType,
    questions,
    setQuestions,
    selectedQuestion,
    setSelectedQuestion
  } = useCreateProject();

  useEffect(() => {
    if (match.params.step !== currentStep.toString()) {
      setCurrentStep(Number(match.params.step));
    }
  }, [match.params.step]);

  useEffect(() => {
    async function fetchQuestions() {
      setLoading(true);
      const response = await projectsStore!.getGuidedBriefingQuestions(
        Number(dataStore!.currentOrganizationId!),
        selectedStrategyType!.id,
        {
          guided_briefing_description: projectChallenge,
          guided_briefing_target_group_description: projectTargetGroup,
          guided_briefing_service_description: projectService,
          guided_briefing_business_target_description: projectBusinessTarget,
          guided_briefing_characteristic_description:
            projectBusinessTargetFormat
        }
      );
      setQuestions(response['questions'] || []);
      setLoading(false);
    }

    if (questions.length === 0) {
      fetchQuestions();
    }
  }, []);

  return (
    <>
      {loading || submitted ? (
        <CardWrapper>
          <div
            className="l-item-stack l-item-stack--center"
            style={{ marginTop: '1rem', padding: '0 1.5rem' }}
          >
            <Headline size="L" center={true}>
              <FormattedMessage
                id={
                  submitted
                    ? 'create project creating loading'
                    : 'create project questions loading'
                }
              />
            </Headline>
            <img
              src="/images/robot.gif"
              alt=""
              style={{
                width: 'min(10vw, 210px)',
                height: 'auto'
              }}
            />
          </div>
        </CardWrapper>
      ) : (
        <>
          <div style={{ marginBottom: '6rem' }}>
            <Flow>
              {questions.map((question: any, index: number) => (
                <RainbowCard
                  shrink={true}
                  key={index}
                  clickable={true}
                  onCheckboxChange={() => {
                    // other wise we run in the 0 = false issue
                    setSelectedQuestion(question);
                  }}
                  checked={selectedQuestion === question}
                >
                  <CardHeader
                    title={`Vorschlag ${index + 1}`}
                    single={true}
                    onEditClick={undefined}
                  />
                  {question}
                </RainbowCard>
              ))}
            </Flow>
          </div>
          <SessionFixed>
            <MainButton
              secondary={true}
              onClick={() => {
                history.goBack();
                setCurrentStep(currentStep - 1);
              }}
            >
              <FormattedMessage id="Back" />
            </MainButton>
            <MainButton
              onClick={async () => {
                setSubmitted(true);
                try {
                  const result = await projectsStore!.createGuidedProject(
                    dataStore!.currentOrganizationId!,
                    selectedStrategyType!.id,
                    projectTopic,
                    {
                      question: selectedQuestion!,
                      guided_briefing_description: projectChallenge,
                      guided_briefing_target_group_description:
                        projectTargetGroup,
                      guided_briefing_service_description: projectService,
                      guided_briefing_business_target_description:
                        projectBusinessTarget,
                      guided_briefing_characteristic_description:
                        projectBusinessTargetFormat
                    }
                  );

                  history.push(
                    ROUTE_PROJECT.replace(
                      ':orgId',
                      dataStore!.currentOrganizationId!.toString()
                    ).replace(':projectId', result!.id.toString())
                  );
                } catch (error) {
                  setSubmitted(false);
                  // Handle both error objects with 'errors' array and direct error messages
                  const errorMessage =
                    typeof error === 'object' && error !== null
                      ? (error as { errors?: string[] }).errors?.[0] ||
                        String(error)
                      : String(error);
                  applicationStore.setFlashMessage(errorMessage, 'error');
                }
              }}
              disabled={!selectedQuestion}
            >
              <FormattedMessage id="Create" />
            </MainButton>
          </SessionFixed>
        </>
      )}
    </>
  );
}

export default observer(SelectQuestionScreen);

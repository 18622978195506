import MainButton from 'components/MainButton';
import ProjectType from 'components/ProjectType';
import { StrategyType } from 'components/ProjectType/ProjectType';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { SessionFixed } from 'domain/assistant/session-elements';
import { useStores } from 'index';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import {
  ROUTE_CREATE_PROJECT_GUIDED,
  ROUTE_CREATE_PROJECT_MANUAL
} from 'utils/constants/routes';

function SelectStrategyTypeScreen() {
  const match = useRouteMatch<{ step: string }>();
  const history = useHistory();
  const { projectsStore } = useStores();
  const {
    setSelectedStrategyType,
    selectedStrategyType,
    guided,
    orgId,
    setCurrentStep,
    currentStep
  } = useCreateProject();

  useEffect(() => {
    const step = parseInt(match.params.step, 10);
    if (!isNaN(step) && step !== currentStep) {
      setCurrentStep(step);
    }
  }, [match.params.step]);

  const [tmpStrategyType, setTmpStrategyType] = useState<
    StrategyType | undefined
  >(selectedStrategyType);
  const [strategyTypes, setStrategyTypes] = useState([
    { id: '', type: '', description: '', icon: '', sorting: 99999 }
  ]);

  useEffect(() => {
    async function fetchStrategyTypes() {
      const strategyTypes = await projectsStore.getStrategyTypes();
      setStrategyTypes(strategyTypes);
    }
    fetchStrategyTypes();
  }, [projectsStore]);

  const sortedStrategyTypes = useMemo(() => {
    return strategyTypes.sort((a, b) => a.sorting - b.sorting);
  }, [strategyTypes]);

  const handleNext = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!tmpStrategyType || !orgId) return;

    setSelectedStrategyType(tmpStrategyType);
    setCurrentStep(2);

    const route = guided
      ? ROUTE_CREATE_PROJECT_GUIDED
      : ROUTE_CREATE_PROJECT_MANUAL;
    const path = route
      .replace(':orgId', orgId.toString())
      .replace(':step', '2');
    history.push(path);
  };

  return (
    <>
      <ProjectType
        strategyTypes={sortedStrategyTypes}
        selectedStrategyType={tmpStrategyType}
        onStrategyTypeChanged={setTmpStrategyType}
      />
      <SessionFixed>
        <MainButton
          secondary={true}
          onClick={(event) => {
            event.preventDefault();
            history.goBack();
          }}
        >
          <FormattedMessage id="Cancel" />
        </MainButton>
        <MainButton
          type="submit"
          disabled={!tmpStrategyType}
          onClick={handleNext}
        >
          <FormattedMessage id="Next" />
        </MainButton>
      </SessionFixed>
    </>
  );
}

export default SelectStrategyTypeScreen;

import {
  applySnapshot,
  cast,
  getParent,
  getSnapshot,
  types
} from 'mobx-state-tree';

import { ROUTE_LAB } from 'utils/constants/routes';
import sortByField from 'utils/sort/field';
import createMap, { createMapWithTransform } from 'utils/store/createMap';
import AssistantInvitationModel, {
  AssistantInvitationModelType,
  createAssistantInvitationModel
} from './AssistantInvitationModel';
import AssistantSessionModel, {
  AssistantSessionModelType,
  createAssistantSessionModel
} from './AssistantSessionModel';
import AssistantStatsModel, {
  AssistantStatsModelType
} from './AssistantStatsModel';
import BenchmarkModel, {
  BenchmarkModelType,
  createBenchmarkModel
} from './BenchmarkModel';
import BriefingModel, {
  BriefingModelType,
  FluidBriefingModelType,
  createFluidBriefingModel
} from './BriefingModel';
import ClusterModel, {
  ClusterModelType,
  createClusterModel
} from './ClusterModel';
import CommentModel, { CommentModelType } from './CommentModel';
import CompanyNameSuggestionModel, {
  createCompanyNameSuggestionModel
} from './CompanySuggestionModel';
import { FaqPageModelType } from './FaqPageModel';
import GPTRequestCounterModel, {
  GPTRequestCounterModelType,
  createGPTRequestCounterModel
} from './GPTRequestCounterModel';
import HypothesisModel, {
  HypothesisModelType,
  createHypothesisModel
} from './HypothesisModel';
import LearningModel, {
  LearningModelType,
  createLearningModel
} from './LearningModel';
import OrgMemberModel, { OrgMemberModelType } from './OrgMemberModel';
import OrgServiceModel, { OrgServiceModelType } from './OrgServiceModel';
import OrgTargetGroupModel, {
  OrgTargetGroupModelType
} from './OrgTargetGroupModel';
import OrganizationModel, {
  OrganizationModelType,
  createOrganizationModel
} from './OrganizationModel';
import PainpointModel, {
  PainpointModelType,
  createPainpointModel
} from './PainpointModel';
import ProjectMemberModel, {
  ProjectMemberModelType
} from './ProjectMemberModel';
import ProjectModel, { ProjectModelType } from './ProjectModel';
import PrototypeModel, {
  PrototypeModelType,
  createPrototypeModel
} from './PrototypeModel';
import { createPrototypeScreenModel } from './PrototypeScreenModel';
import UserModel, { UserModelType } from './UserModel';

export type Commentable =
  | BriefingModelType
  | PainpointModelType
  | BenchmarkModelType
  | HypothesisModelType
  | PrototypeModelType
  | LearningModelType
  | FaqPageModelType;

export type Bookmarkable =
  | ProjectModelType
  | BriefingModelType
  | PainpointModelType
  | BenchmarkModelType
  | HypothesisModelType
  | PrototypeModelType
  | LearningModelType;

export type BookmarkableEnumType =
  | 'Project'
  | 'Briefing'
  | 'Painpoint'
  | 'Benchmark'
  | 'Hypothesis'
  | 'Prototype'
  | 'Learning';

// This store is used to store all object entities.
// Having all objects in one store allows us to use references
// and only store every entity once.
// Organization and Project entities will also define the current
// working space of the web app.
const DataStore = types
  .model('DataStore', {
    // global content
    users: types.map(UserModel),
    organizations: types.map(OrganizationModel),
    projects: types.map(ProjectModel),
    assistantSessions: types.map(AssistantSessionModel),
    sentAssistantInvitations: types.map(AssistantInvitationModel),

    // context
    user: types.maybe(types.reference(UserModel)),
    organization: types.maybe(types.reference(OrganizationModel)),
    orgMembers: types.map(OrgMemberModel),
    orgTargetGroups: types.map(OrgTargetGroupModel),
    orgServices: types.map(OrgServiceModel),
    project: types.maybe(types.reference(ProjectModel)),
    projectMembers: types.map(ProjectMemberModel),

    projectAssistantStats: types.maybe(AssistantStatsModel),
    receivedInvitationsCount: types.maybe(types.number),

    // context content
    briefing: types.maybe(BriefingModel),
    clusters: types.map(ClusterModel),
    painpoints: types.map(PainpointModel),
    benchmarks: types.map(BenchmarkModel),
    hypotheses: types.map(HypothesisModel),
    gptRequestCounters: types.map(GPTRequestCounterModel),
    prototypes: types.map(PrototypeModel),
    learnings: types.map(LearningModel),
    customers: types.map(OrganizationModel),
    // query response lists
    assistantSessionsList: types.maybe(
      types.array(types.reference(AssistantSessionModel))
    ),
    sentAssistantInvitationsList: types.maybe(
      types.array(types.reference(AssistantInvitationModel))
    ),
    projectsList: types.maybe(types.array(types.reference(ProjectModel))),
    benchmarksList: types.maybe(types.array(types.reference(BenchmarkModel))),
    painpointsList: types.maybe(types.array(types.reference(PainpointModel))),
    hypothesesList: types.maybe(types.array(types.reference(HypothesisModel))),
    gptRequestCounterList: types.maybe(
      types.array(types.reference(GPTRequestCounterModel))
    ),
    prototypesList: types.maybe(types.array(types.reference(PrototypeModel))),
    learningsList: types.maybe(types.array(types.reference(LearningModel))),
    customersList: types.maybe(types.array(types.reference(OrganizationModel))),
    // items
    assistantSessionItem: types.maybe(
      types.safeReference(AssistantSessionModel)
    ),
    commentItem: types.maybe(types.safeReference(CommentModel)),
    benchmarkItem: types.maybe(types.safeReference(BenchmarkModel)),
    painpointItem: types.maybe(types.safeReference(PainpointModel)),
    hypothesisItem: types.maybe(types.safeReference(HypothesisModel)),
    gptRequestCounterItem: types.maybe(
      types.safeReference(GPTRequestCounterModel)
    ),
    prototypeItem: types.maybe(types.safeReference(PrototypeModel)),
    learningItem: types.maybe(types.safeReference(LearningModel)),

    // selected relation
    hypothesisItemPainpoint: types.maybe(types.safeReference(PainpointModel)),
    hypothesisItemBenchmarks: types.maybe(
      types.array(types.reference(BenchmarkModel))
    ),
    prototypeItemHypothesis: types.maybe(types.safeReference(HypothesisModel)),
    learningItemHypothesis: types.maybe(types.safeReference(HypothesisModel)),

    // network
    networkOrganizationsList: types.maybe(
      types.array(types.reference(OrganizationModel))
    ),

    suggestions: types.maybe(types.map(CompanyNameSuggestionModel))
  })
  .actions((self) => {
    const clearContextContent = () => {
      // lists first
      self.projectsList = undefined;
      self.benchmarksList = undefined;
      self.painpointsList = undefined;
      self.hypothesesList = undefined;
      self.gptRequestCounterList = undefined;
      self.prototypesList = undefined;
      self.learningsList = undefined;
      self.customersList = undefined;
      // object store last
      // self.projects.clear();
      self.projectAssistantStats = undefined;

      self.sentAssistantInvitationsList = undefined;
      self.sentAssistantInvitations.clear();

      self.briefing = undefined;
      self.clusters.clear();
      self.painpoints.clear();
      self.benchmarks.clear();
      self.hypotheses.clear();
      self.gptRequestCounters.clear();
      self.prototypes.clear();
      self.learnings.clear();
      self.customers.clear();
    };

    const clearStore = () => {
      clearContextContent();

      // clear all objects - this is for logout
      self.user = undefined;
      self.organization = undefined;
      self.project = undefined;

      self.networkOrganizationsList = undefined;

      self.assistantSessionsList?.clear();
      self.sentAssistantInvitationsList?.clear();
      self.assistantSessions.clear();
      self.sentAssistantInvitations?.clear();
      self.receivedInvitationsCount = undefined;

      self.orgMembers.clear();
      self.orgTargetGroups.clear();
      self.orgServices.clear();
      self.projectMembers.clear();
      self.users.clear();
      self.organizations.clear();
      self.projects.clear();
      self.customers.clear();
    };

    // == organizations ==
    const setOrganizations = (organizations: OrganizationModelType[]) => {
      self.organizations = createMap(organizations, createOrganizationModel);
    };

    const setOrganization = (organization: OrganizationModelType) => {
      const hasOrgChanged =
        self.organization && self.organization.id !== organization.id;

      self.organizations.put(organization);
      self.organization = self.organizations.get(organization.id.toString());

      if (hasOrgChanged) {
        clearContextContent();

        self.project = undefined;
        self.projects.clear();
      }
    };

    const addOrganization = (organization: OrganizationModelType) => {
      self.organizations.put(organization);
      return self.organizations.get(organization.id.toString());
    };

    const addOrgMember = (member: OrgMemberModelType) => {
      self.orgMembers.put(member);
    };

    const removeOrgMember = (userId: number) => {
      self.orgMembers.delete(userId.toString());
    };

    const clearOrgMembers = () => {
      self.orgMembers.clear();
    };

    // == org target groups ==
    const addOrgTargetGroup = (targetGroup: OrgTargetGroupModelType) => {
      self.orgTargetGroups.put(targetGroup);
    };

    const removeOrgTargetGroup = (targetGroupId: number) => {
      self.orgTargetGroups.delete(targetGroupId.toString());
    };

    const clearOrgTargetGroups = () => {
      self.orgTargetGroups.clear();
    };

    // == org services ==
    const addOrgService = (service: OrgServiceModelType) => {
      self.orgServices.put(service);
    };

    const removeOrgService = (serviceId: number) => {
      self.orgServices.delete(serviceId.toString());
    };

    const clearOrgServices = () => {
      self.orgServices.clear();
    };

    // == customers ==
    const setCustomers = (customers: OrganizationModelType[]) => {
      self.customers = createMap(customers, createOrganizationModel);
    };

    const setCustomersList = (customersList?: any) => {
      if (!customersList) {
        self.customersList = undefined;
      } else {
        self.customersList = cast(customersList);
      }
    };

    const addCustomer = (
      customer: OrganizationModelType
    ): OrganizationModelType | undefined => {
      self.customers.put(customer);
      addToCustomersList(customer);

      return self.customers.get(customer.id.toString());
    };

    const addToCustomersList = (item: OrganizationModelType) => {
      if (self.customersList) {
        self.customersList.push(item);
      }
    };

    const removeCustomer = (customerId: number) => {
      self.customers.delete(customerId.toString());
    };

    // == projects ==
    const addProject = (
      project: ProjectModelType,
      isItem: boolean = false
    ): ProjectModelType | undefined => {
      const restoreItem =
        isItem && self.project && self.project.id === project.id;

      self.projects.put(project);
      const newProject = self.projects.get(project.id.toString());

      if (restoreItem) {
        self.project = newProject;
      }

      return newProject;
    };

    const deleteProject = (projectId: number) => {
      const project = self.projects.get(projectId.toString());
      if (!project) {
        return;
      }

      if (self.project === project) {
        self.project = undefined;
      }

      if (self.projectsList) {
        self.projectsList.remove(project);
      }

      self.projects.delete(projectId.toString());
    };

    const setProjectsList = (projectsList?: any) => {
      if (!projectsList) {
        self.projectsList = undefined;
      } else {
        self.projectsList = cast(projectsList);
      }
    };

    const addToProjectsList = (item: ProjectModelType) => {
      if (self.projectsList) {
        self.projectsList.push(item);
      }
    };

    const setProject = (project: ProjectModelType) => {
      const hasProjectChanged = self.project && self.project.id !== project.id;

      self.project = project;

      if (hasProjectChanged) {
        clearContextContent();
      }
    };

    const setProjectAssistantStats = (
      assistantStats?: AssistantStatsModelType | undefined
    ) => {
      self.projectAssistantStats = assistantStats || undefined;
    };

    const setReceivedInvitationsCount = (count?: number | undefined) => {
      self.receivedInvitationsCount = count;
    };

    const addProjectMember = (member: ProjectMemberModelType) => {
      self.projectMembers.put(member);
    };

    const deleteProjectMember = (userId: number) => {
      self.projectMembers.delete(userId.toString());
    };

    const clearProjectMembers = () => {
      self.projectMembers.clear();
    };

    const updateProjectUserSettings = () => {
      if (self.project) {
        self.project.has_user_settings = true;
      }

    }

    // == user ==
    const addUser = (user: UserModelType): UserModelType | undefined => {
      self.users.put(user);
      return self.users.get(user.id.toString());
    };

    const setUser = (user: UserModelType) => {
      addUser(user);
      self.user = self.users.get(user.id.toString());
    };

    const setBriefing = (briefing?: BriefingModelType) => {
      self.briefing = briefing;
    };

    const updateProjectBriefing = (
      briefing: BriefingModelType | FluidBriefingModelType
    ) => {
      if (!self.project) {
        return;
      }

      self.project.briefing = createFluidBriefingModel(briefing);
    };

    const setCommentItem = (comment?: CommentModelType) => {
      self.commentItem = comment;
      return self.commentItem;
    };

    const replaceCommentItem = (item?: CommentModelType) => {
      if (!item || !self.commentItem) {
        return self.commentItem;
      }

      // We want to update comment items from everywhere so resolve the map
      // containing the current comment first then update comment there.
      const parentMap: Map<string, CommentModelType> = getParent(
        self.commentItem
      );
      parentMap.set(self.commentItem.id.toString(), item);

      return self.commentItem;
    };

    // == clusters ==
    const addCluster = (
      cluster: ClusterModelType
    ): ClusterModelType | undefined => {
      self.clusters.put(cluster);
      return self.clusters.get(cluster.id.toString());
    };

    const deleteCluster = (clusterId: number) => {
      self.clusters.delete(clusterId.toString());
    };

    const clearClusters = () => {
      self.clusters.clear();
    };

    const updateClusterFromCable = (clusterId: number, patch?: any) => {
      if (!patch) {
        return;
      }

      const cluster = self.clusters.get(clusterId.toString());
      const newModel = createClusterModel(patch);

      if (!cluster) {
        addCluster(newModel);
        return;
      }

      const oldSnapshot = getSnapshot(cluster);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(cluster, {
        ...oldSnapshot,
        ...newSnapshot
      });
    };

    // == painpoints ==
    const addPainpoint = (
      painpoint: PainpointModelType,
      isItem: boolean = false
    ): PainpointModelType | undefined => {
      const restoreItem =
        isItem && self.painpointItem && self.painpointItem.id === painpoint.id;

      self.painpoints.put(painpoint);
      const newPainpoint = self.painpoints.get(painpoint.id.toString());

      if (restoreItem) {
        self.painpointItem = newPainpoint;
      }

      return newPainpoint;
    };

    const convertAndAddPainpoint = (
      data: any,
      isItem: boolean = false
    ): PainpointModelType | undefined => {
      const painpoint = createPainpointModel(data);

      if (painpoint) {
        if (!self.hypothesisItem) {
          if (Array.isArray(data?.hypotheses)) {
            for (const hypothesis of data.hypotheses) {
              painpoint.putHypothesis(
                convertAndAddHypothesis(hypothesis, false, true)
              );
            }
          }
        }

        if (Array.isArray(data?.prototypes)) {
          for (const prototype of data.prototypes) {
            painpoint.putPrototype(
              convertAndAddPrototype(prototype, false, true)
            );
          }
        }
      }

      return addPainpoint(painpoint, isItem);
    };

    const deletePainpoint = (painpointId: number) => {
      const painpoint = self.painpoints.get(painpointId.toString());
      if (!painpoint) {
        return;
      }

      if (self.painpointsList) {
        self.painpointsList.remove(painpoint);
        self.project!.decrementPainpointCount();
      }

      self.painpoints.delete(painpointId.toString());
    };

    const setPainpointsList = (painpointsList?: any) => {
      if (!painpointsList) {
        self.painpointsList = undefined;
      } else {
        self.painpointsList = cast(painpointsList);
      }
    };

    const addToPainpointsList = (item: PainpointModelType) => {
      if (self.painpointsList && !self.painpointsList.includes(item)) {
        self.project!.incrementPainpointCount();
        self.painpointsList.push(item);
      }
    };

    const setPainpointItem = (painpoint?: PainpointModelType) => {
      self.painpointItem = painpoint;
    };

    const updatePainpointFromCable = (painpointId: number, patch?: any) => {
      if (!patch) {
        return;
      }

      let painpoint = self.painpoints.get(painpointId.toString());

      if (!painpoint) {
        painpoint = convertAndAddPainpoint(patch);
        painpoint && addToPainpointsList(painpoint);
        return;
      }

      const newModel = createPainpointModel(patch);
      if (!newModel) {
        return;
      }

      const oldSnapshot = getSnapshot(painpoint);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(painpoint, {
        ...oldSnapshot,
        ...newSnapshot,
        bookmark_id: oldSnapshot.bookmark_id
      });

      if (self.painpointsList?.includes(painpoint)) {
        self.painpointsList.remove(painpoint);
        self.project!.decrementPainpointCount();
        addToPainpointsList(painpoint);
      }
    };

    // == benchmarks ==
    const addBenchmark = (
      benchmark: BenchmarkModelType,
      isItem: boolean = false
    ): BenchmarkModelType | undefined => {
      const restoreItem =
        isItem && self.benchmarkItem && self.benchmarkItem.id === benchmark.id;

      const id = benchmark.id.toString();

      let newBenchmark: BenchmarkModelType;

      if (self.benchmarks.has(id)) {
        // won't break references - required for assistant
        // TODO use same logic for all object types? could avoid re-creating search result arrays.
        newBenchmark = self.benchmarks.get(id)!;
        applySnapshot(newBenchmark, getSnapshot(benchmark));
      } else {
        self.benchmarks.put(benchmark);
        newBenchmark = self.benchmarks.get(id)!;
      }

      if (restoreItem) {
        self.benchmarkItem = newBenchmark;
      }

      return newBenchmark;
    };

    const deleteBenchmark = (benchmarkId: number) => {
      const benchmark = self.benchmarks.get(benchmarkId.toString());
      if (!benchmark) {
        return;
      }

      if (self.benchmarksList) {
        self.benchmarksList.remove(benchmark);
        self.project!.decrementBenchmarkCount();
      }
      if (self.hypothesisItemBenchmarks) {
        self.hypothesisItemBenchmarks.remove(benchmark);
      }

      self.benchmarks.delete(benchmarkId.toString());
    };

    const setBenchmarksList = (benchmarksList?: any) => {
      if (!benchmarksList) {
        self.benchmarksList = undefined;
      } else {
        self.benchmarksList = cast(benchmarksList);
      }
    };

    const addToBenchmarksList = (item: BenchmarkModelType) => {
      if (self.benchmarksList) {
        self.project!.incrementBenchmarkCount();
        self.benchmarksList.push(item);
      }
    };

    const setBenchmarkItem = (benchmark?: BenchmarkModelType) => {
      self.benchmarkItem = benchmark;
    };

    const updateBenchmarkFromCable = (benchmarkId: number, patch?: any) => {
      if (!patch) {
        return;
      }

      let benchmark = self.benchmarks.get(benchmarkId.toString());

      if (!benchmark) {
        benchmark = createBenchmarkModel(patch);
        benchmark && addBenchmark(benchmark);
        addToBenchmarksList(benchmark);
        return;
      }

      const newModel = createBenchmarkModel(patch);
      if (!newModel) {
        return;
      }

      const oldSnapshot = getSnapshot(benchmark);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(benchmark, {
        ...oldSnapshot,
        ...newSnapshot,
        bookmark_id: oldSnapshot.bookmark_id
      });

      if (self.benchmarksList?.includes(benchmark)) {
        self.benchmarksList.remove(benchmark);
        self.project!.decrementBenchmarkCount();
        addToBenchmarksList(benchmark);
      }
    };

    // == prototypes ==
    const addPrototype = (
      prototype: PrototypeModelType,
      isItem: boolean = false
    ): PrototypeModelType | undefined => {
      const restoreItem =
        isItem && self.prototypeItem && self.prototypeItem.id === prototype.id;

      self.prototypes.put(prototype);
      const newPrototype = self.prototypes.get(prototype.id.toString());

      if (restoreItem) {
        self.prototypeItem = newPrototype;
      }

      return newPrototype;
    };

    const convertAndAddPrototype = (
      data: any,
      isItem: boolean = false,
      skipHypothesis: boolean = false
    ): PrototypeModelType | undefined => {
      const prototype = createPrototypeModel(data);

      if (!skipHypothesis && data.hypothesis) {
        prototype.setHypothesis(convertAndAddHypothesis(data.hypothesis));
      }

      return addPrototype(prototype, isItem);
    };

    const deletePrototype = (prototypeId: number) => {
      const prototype = self.prototypes.get(prototypeId.toString());
      if (!prototype) {
        return;
      }

      if (self.prototypesList) {
        self.prototypesList.remove(prototype);
        self.project!.decrementPrototypesCount();
      }

      self.prototypes.delete(prototypeId.toString());
    };

    const setPrototypesList = (prototypesList?: any) => {
      if (!prototypesList) {
        self.prototypesList = undefined;
      } else {
        self.prototypesList = cast(prototypesList);
      }
    };

    const addToPrototypesList = (item: PrototypeModelType) => {
      if (self.prototypesList) {
        self.project!.incrementPrototypesCount();
        self.prototypesList.push(item);
      }
    };

    const setPrototypeItem = (prototype?: PrototypeModelType) => {
      self.prototypeItem = prototype;
      self.prototypeItemHypothesis = prototype?.hypothesis
        ? prototype.hypothesis
        : undefined;
    };

    const setPrototypeItemHypothesis = (hypothesis?: HypothesisModelType) => {
      self.prototypeItemHypothesis = hypothesis;
    };

    const updatePrototypeFromCable = (prototypeId: number, patch?: any) => {
      if (!patch) {
        return;
      }

      let prototype = self.prototypes.get(prototypeId.toString());

      if (!prototype) {
        prototype = convertAndAddPrototype(patch);
        prototype && addToPrototypesList(prototype);
        return;
      }

      const newModel = createPrototypeModel(patch);
      if (!newModel) {
        return;
      }

      const oldSnapshot = getSnapshot(prototype);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(prototype, {
        ...oldSnapshot,
        ...newSnapshot,
        bookmark_id: oldSnapshot.bookmark_id,
        hypothesis: oldSnapshot.hypothesis
      });

      if (self.prototypesList?.includes(prototype)) {
        self.prototypesList.remove(prototype);
        self.project!.decrementPrototypesCount();
        addToPrototypesList(prototype);
      }
    };
    // == learnings ==
    const addLearning = (
      learning: LearningModelType,
      isItem: boolean = false
    ): LearningModelType | undefined => {
      const restoreItem =
        isItem && self.learningItem && self.learningItem.id === learning.id;

      self.learnings.put(learning);
      const newLearning = self.learnings.get(learning.id.toString());

      if (restoreItem) {
        self.learningItem = newLearning;
      }

      return newLearning;
    };

    const convertAndAddLearning = (
      data: any,
      isItem: boolean = false,
      skipHypothesis: boolean = false
    ): LearningModelType | undefined => {
      const learning = createLearningModel(data);

      if (!skipHypothesis && data.hypothesis) {
        learning.setHypothesis(convertAndAddHypothesis(data.hypothesis, false));
      }

      return addLearning(learning, isItem);
    };

    const deleteLearning = (learningId: number) => {
      const learning = self.learnings.get(learningId.toString());
      if (!learning) {
        return;
      }

      if (self.learningsList) {
        self.learningsList.remove(learning);
      }

      self.learnings.delete(learningId.toString());
    };

    const setLearningsList = (learningsList?: any) => {
      if (!learningsList) {
        self.learningsList = undefined;
      } else {
        self.learningsList = cast(learningsList);
      }
    };

    const addToLearningsList = (item: LearningModelType) => {
      if (self.learningsList) {
        self.learningsList.push(item);
      }
    };

    const setLearningItem = (learning?: LearningModelType) => {
      self.learningItem = learning;
      self.learningItemHypothesis = learning?.hypothesis
        ? learning.hypothesis
        : undefined;
    };

    const setLearningItemHypothesis = (hypothesis?: HypothesisModelType) => {
      self.learningItemHypothesis = hypothesis;
    };

    // == bookmarks ==
    const setBookmarkId = (
      elementType: BookmarkableEnumType,
      elementId: number,
      bookmarkId: number | undefined | null
    ) => {
      let element: Bookmarkable | undefined;

      switch (elementType) {
        case 'Project':
          element = self.projects.get(elementId.toString());
          break;

        case 'Briefing':
          if (self.briefing && self.briefing.id === elementId) {
            element = self.briefing;
          }
          break;

        case 'Painpoint':
          element = self.painpoints.get(elementId.toString());
          break;

        case 'Benchmark':
          element = self.benchmarks.get(elementId.toString());
          break;

        case 'Hypothesis':
          element = self.hypotheses.get(elementId.toString());
          break;

        case 'Prototype':
          element = self.prototypes.get(elementId.toString());
          break;

        case 'Learning':
          element = self.learnings.get(elementId.toString());
          break;

        default:
      }

      if (!element) {
        return;
      }

      if (!bookmarkId) {
        // remove
        if (
          element.bookmark_id &&
          element.bookmarks_count &&
          (elementType == 'Project' || elementType == 'Briefing')
        ) {
          element.bookmarks_count = element.bookmarks_count - 1;
        }

        element.bookmark_id = undefined;
      } else {
        // add
        if (
          !element.bookmark_id &&
          (elementType == 'Project' || elementType == 'Briefing')
        ) {
          element.bookmarks_count = !element.bookmarks_count
            ? 1
            : element.bookmarks_count + 1;
        }

        element.bookmark_id = bookmarkId;
      }

      // Lists (arrays of references) aren't noticed automatically so update manually.
      // TODO Remove bookmark/unbookmark from lists so we don't need this stuff?
      // TODO Create methods for list updates?
      switch (elementType) {
        case 'Project':
          // self.projects.put(createProjectModel(element));
          if (self.projectsList?.includes(element as ProjectModelType)) {
            self.projectsList.remove(element as ProjectModelType);
            self.projectsList.push(element as ProjectModelType);
          }
          break;

        case 'Painpoint':
          if (self.painpointsList?.includes(element as PainpointModelType)) {
            self.painpointsList.remove(element as PainpointModelType);
            self.painpointsList.push(element as PainpointModelType);
          }
          break;

        case 'Benchmark':
          if (self.benchmarksList?.includes(element as BenchmarkModelType)) {
            self.benchmarksList.remove(element as BenchmarkModelType);
            self.benchmarksList.push(element as BenchmarkModelType);
          }
          break;

        case 'Hypothesis':
          if (self.hypothesesList?.includes(element as HypothesisModelType)) {
            self.hypothesesList.remove(element as HypothesisModelType);
            self.hypothesesList.push(element as HypothesisModelType);
          }
          break;

        case 'Prototype':
          if (self.prototypesList?.includes(element as PrototypeModelType)) {
            self.prototypesList.remove(element as PrototypeModelType);
            self.prototypesList.push(element as PrototypeModelType);
          }
          break;

        case 'Learning':
          if (self.learningsList?.includes(element as LearningModelType)) {
            self.learningsList.remove(element as LearningModelType);
            self.learningsList.push(element as LearningModelType);
          }
          break;

        default:
      }
    };

    // == hypothesis ==
    const addHypothesis = (
      hypothesis: HypothesisModelType,
      isItem: boolean = false
    ): HypothesisModelType | undefined => {
      const restoreItem =
        isItem &&
        self.hypothesisItem &&
        self.hypothesisItem.id === hypothesis.id;

      self.hypotheses.put(hypothesis);
      const newHypothesis = self.hypotheses.get(hypothesis.id.toString());

      if (restoreItem) {
        self.hypothesisItem = newHypothesis;
      }

      return newHypothesis;
    };

    const convertAndAddHypothesis = (
      data: any,
      isItem: boolean = false,
      skipPainpoint: boolean = false
    ): HypothesisModelType | undefined => {
      const hypothesis = createHypothesisModel(data);

      if (!skipPainpoint && data.painpoint) {
        hypothesis.setPainpoint(convertAndAddPainpoint(data.painpoint));
      }

      if (Array.isArray(data.benchmarks)) {
        const benchmarks: BenchmarkModelType[] = [];
        for (const benchmarkData of data.benchmarks) {
          const benchmark = addBenchmark(createBenchmarkModel(benchmarkData));
          if (benchmark) {
            benchmarks.push(benchmark);
          }
        }

        hypothesis.setBenchmarks(benchmarks);
      }

      return addHypothesis(hypothesis, isItem);
    };

    const deleteHypothesis = (hypothesisId: number) => {
      const hypothesis = self.hypotheses.get(hypothesisId.toString());
      if (!hypothesis) {
        return;
      }

      if (self.hypothesesList) {
        self.hypothesesList.remove(hypothesis);
        self.project!.decrementHypothesesCount();
      }

      self.hypotheses.delete(hypothesisId.toString());
    };

    const setHypothesesList = (hypothesesList?: any) => {
      if (!hypothesesList) {
        self.hypothesesList = undefined;
      } else {
        self.hypothesesList = cast(hypothesesList);
      }
    };

    const addToHypothesesList = (item: HypothesisModelType) => {
      if (self.hypothesesList) {
        self.project!.incrementHypothesesCount();
        self.hypothesesList.push(item);
      }
    };

    const setHypothesisItem = (hypothesis?: HypothesisModelType) => {
      self.hypothesisItem = hypothesis;

      self.hypothesisItemPainpoint = hypothesis?.painpoint
        ? hypothesis.painpoint
        : undefined;

      self.hypothesisItemBenchmarks = cast([]);
      if (hypothesis?.benchmarks) {
        for (const benchmark of hypothesis?.benchmarks.values()) {
          self.hypothesisItemBenchmarks!.push(benchmark);
        }
      }
    };

    const setHypothesisItemPainpoint = (painpoint?: PainpointModelType) => {
      self.hypothesisItemPainpoint = painpoint;
    };

    const setHypothesisItemBechmarks = (benchmarks?: any) => {
      self.hypothesisItemBenchmarks = benchmarks;
    };

    const updateHypothesisFromCable = (hypothesisId: number, patch?: any) => {
      if (!patch) {
        return;
      }

      let hypothesis = self.hypotheses.get(hypothesisId.toString());

      if (!hypothesis) {
        hypothesis = convertAndAddHypothesis(patch);
        hypothesis && addToHypothesesList(hypothesis);
        return;
      }

      const newModel = createHypothesisModel(patch);
      if (!newModel) {
        return;
      }

      const oldSnapshot = getSnapshot(hypothesis);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(hypothesis, {
        ...oldSnapshot,
        ...newSnapshot,
        bookmark_id: oldSnapshot.bookmark_id,
        painpoint: oldSnapshot.painpoint,
        benchmarks: oldSnapshot.benchmarks
      });

      if (self.hypothesesList?.includes(hypothesis)) {
        self.hypothesesList.remove(hypothesis);
        self.project!.decrementHypothesesCount();
        addToHypothesesList(hypothesis);
      }
    };

    // gpt request counter
    const addGPTRequestCounter = (
      gptRequestCounter: GPTRequestCounterModelType,
      isItem: boolean = false
    ): GPTRequestCounterModelType | undefined => {
      const restoreItem =
        isItem &&
        self.gptRequestCounterItem &&
        self.gptRequestCounterItem.id === gptRequestCounter.id;

      self.gptRequestCounters.put(gptRequestCounter);
      const newGPTRequestCounter = self.gptRequestCounters.get(
        gptRequestCounter.id.toString()
      );

      if (restoreItem) {
        self.gptRequestCounterItem = newGPTRequestCounter;
      }

      return newGPTRequestCounter;
    };

    const convertAndAddGPTRequestCounter = (
      data: any,
      isItem: boolean = false
    ): GPTRequestCounterModelType | undefined => {
      const gptRequestCounter = createGPTRequestCounterModel(data);

      return addGPTRequestCounter(gptRequestCounter, isItem);
    };

    const updateGPTRequestCounterFromCable = (
      gptRequestCounterId: number,
      patch?: any
    ) => {
      if (!patch) {
        return;
      }

      let gptRequestCounter = self.gptRequestCounters.get(
        gptRequestCounterId.toString()
      );

      if (!gptRequestCounter) {
        gptRequestCounter = convertAndAddGPTRequestCounter(patch);
        gptRequestCounter && addToGPTRequestCounterList(gptRequestCounter);
        return;
      }

      const newModel = createGPTRequestCounterModel(patch);
      if (!newModel) {
        return;
      }

      const oldSnapshot = getSnapshot(gptRequestCounter);
      const newSnapshot = getSnapshot(newModel);

      applySnapshot(gptRequestCounter, {
        ...oldSnapshot,
        ...newSnapshot
      });

      if (gptRequestCounter.current_amount == gptRequestCounter.target_amount) {
        self.project?.setAiAtWork(false);
      }

      if (self.gptRequestCounterList?.includes(gptRequestCounter)) {
        self.gptRequestCounterList.remove(gptRequestCounter);
        addToGPTRequestCounterList(gptRequestCounter);
      }
    };
    const setGPTRequestCounterList = (gptRequestCounterList?: any) => {
      if (!gptRequestCounterList) {
        self.gptRequestCounterList = undefined;
      } else {
        self.gptRequestCounterList = cast(gptRequestCounterList);
      }
    };

    const addToGPTRequestCounterList = (item: GPTRequestCounterModelType) => {
      if (self.gptRequestCounterList) {
        self.gptRequestCounterList.push(item);
      }
    };

    const removeGPTRequestCounter = (gptRequestCounterId: number) => {
      const gptRequestCounter = self.gptRequestCounters.get(
        gptRequestCounterId.toString()
      );
      if (!gptRequestCounter) {
        return;
      }

      if (self.gptRequestCounterList) {
        self.gptRequestCounterList.remove(gptRequestCounter);
      }

      self.gptRequestCounters.delete(gptRequestCounterId.toString());
    };

    // == assistant sessions ==
    const addAssistantSession = (
      assistantSession: AssistantSessionModelType,
      isItem: boolean = false
    ): AssistantSessionModelType | undefined => {
      const restoreItem =
        isItem &&
        self.assistantSessionItem &&
        self.assistantSessionItem.id === assistantSession.id;

      self.assistantSessions.put(assistantSession);
      const newAssistantSession = self.assistantSessions.get(
        assistantSession.id.toString()
      );

      if (restoreItem) {
        self.assistantSessionItem = newAssistantSession;
      }

      return newAssistantSession;
    };

    const convertAndAddAssistantSession = (
      data: any,
      isItem: boolean = false
    ): AssistantSessionModelType | undefined => {
      const assistantSession = createAssistantSessionModel(data);

      if (!assistantSession) {
        return undefined;
      }

      if (Array.isArray(data.benchmarks)) {
        const benchmarks: BenchmarkModelType[] = [];
        for (const benchmarkData of data.benchmarks) {
          const benchmark = addBenchmark(createBenchmarkModel(benchmarkData));
          if (benchmark) {
            benchmarks.push(benchmark);
          }
        }

        assistantSession.setBenchmarks(benchmarks);
      }

      if (data.painpoint) {
        const painpoint = addPainpoint(createPainpointModel(data.painpoint));
        assistantSession.setPainpoint(painpoint);
      }

      if (data.prototype_screens) {
        const prototypeScreens = createMap(
          data.prototype_screens,
          createPrototypeScreenModel
        );
        assistantSession.setPrototypeScreens(prototypeScreens);
      }

      assistantSession.setSynced(true);

      return addAssistantSession(assistantSession, isItem);
    };

    const deleteAssistantSession = (assistantSessionId: number) => {
      const assistantSession = self.assistantSessions.get(
        assistantSessionId.toString()
      );
      if (!assistantSession) {
        return;
      }

      if (self.assistantSessionsList) {
        self.assistantSessionsList.remove(assistantSession);
      }

      self.assistantSessions.delete(assistantSessionId.toString());
    };

    const setAssistantSessionsList = (assistantSessionsList?: any) => {
      if (!assistantSessionsList) {
        self.assistantSessionsList = undefined;
      } else {
        self.assistantSessionsList = cast(assistantSessionsList);
      }
    };

    const addToAssistantSessionsList = (item: AssistantSessionModelType) => {
      if (self.assistantSessionsList) {
        self.assistantSessionsList.push(item);
      }
    };

    const setAssistantSessionItem = (
      assistantSession?: AssistantSessionModelType
    ) => {
      self.assistantSessionItem = assistantSession;
    };

    const addSentAssistantInvitation = (
      assistantInvitation: AssistantInvitationModelType
    ): AssistantInvitationModelType | undefined => {
      self.sentAssistantInvitations.put(assistantInvitation);
      const newAssistantInvitation = self.sentAssistantInvitations.get(
        assistantInvitation.id.toString()
      );

      return newAssistantInvitation;
    };

    const convertAndAddSentAssistantInvitation = (
      data: any
    ): AssistantInvitationModelType | undefined => {
      const assistantInvitation = createAssistantInvitationModel(data);

      if (!assistantInvitation) {
        return undefined;
      }

      return addSentAssistantInvitation(assistantInvitation);
    };

    const deleteSentAssistantSession = (assistantInvitationId: number) => {
      const assistantInvitation = self.sentAssistantInvitations.get(
        assistantInvitationId.toString()
      );
      if (!assistantInvitation) {
        return;
      }

      self.sentAssistantInvitationsList?.remove(assistantInvitation);

      self.sentAssistantInvitations.delete(assistantInvitationId.toString());
    };

    const setSentAssistantInvitationsList = (
      sentAssistantInvitationsList?: any
    ) => {
      if (!sentAssistantInvitationsList) {
        self.sentAssistantInvitationsList = undefined;
      } else {
        self.sentAssistantInvitationsList = cast(sentAssistantInvitationsList);
      }
    };

    const addToSentAssistantInvitationsList = (
      item: AssistantInvitationModelType
    ) => {
      if (self.sentAssistantInvitationsList) {
        self.sentAssistantInvitationsList.push(item);
      }
    };

    // == network ==
    const setNetworkOrganizationsList = (orgsList?: any) => {
      if (!orgsList) {
        self.networkOrganizationsList = undefined;
      } else {
        self.networkOrganizationsList = cast(orgsList);
      }
    };

    // const addToNetworkOrganizationsList = (item: OrganizationModelType) => {
    //   if (self.networkOrganizationsList) {
    //     self.networkOrganizationsList.push(item);
    //   }
    // };

    const setSuggestions = (suggestions: any) => {
      if (!suggestions) {
        self.suggestions = undefined;
        return;
      }

      self.suggestions = createMapWithTransform(
        suggestions,
        createCompanyNameSuggestionModel
      );
    };

    return {
      clearContextContent,
      clearStore,
      setOrganizations,
      setOrganization,
      addOrganization,
      addOrgMember,
      clearOrgMembers,
      removeOrgMember,
      addOrgTargetGroup,
      removeOrgTargetGroup,
      clearOrgTargetGroups,
      addOrgService,
      removeOrgService,
      clearOrgServices,
      setCustomers,
      setCustomersList,
      addCustomer,
      removeCustomer,
      addToCustomersList,
      setProject,
      setProjectAssistantStats,
      setReceivedInvitationsCount,
      addProjectMember,
      deleteProjectMember,
      clearProjectMembers,
      updateProjectUserSettings,
      setProjectsList,
      addProject,
      deleteProject,
      addToProjectsList,
      addUser,
      setUser,
      setBriefing,
      updateProjectBriefing,
      setCommentItem,
      replaceCommentItem,
      addCluster,
      deleteCluster,
      clearClusters,
      updateClusterFromCable,
      addPainpoint,
      convertAndAddPainpoint,
      deletePainpoint,
      setPainpointsList,
      addToPainpointsList,
      setPainpointItem,
      updatePainpointFromCable,
      addBenchmark,
      deleteBenchmark,
      setBenchmarksList,
      addToBenchmarksList,
      setBenchmarkItem,
      updateBenchmarkFromCable,
      addHypothesis,
      convertAndAddHypothesis,
      deleteHypothesis,
      setHypothesesList,
      addToHypothesesList,
      setHypothesisItem,
      setHypothesisItemPainpoint,
      setHypothesisItemBechmarks,
      updateHypothesisFromCable,
      addGPTRequestCounter,
      convertAndAddGPTRequestCounter,
      updateGPTRequestCounterFromCable,
      setGPTRequestCounterList,
      addToGPTRequestCounterList,
      removeGPTRequestCounter,
      addPrototype,
      convertAndAddPrototype,
      deletePrototype,
      setPrototypesList,
      addToPrototypesList,
      setPrototypeItem,
      setPrototypeItemHypothesis,
      updatePrototypeFromCable,
      addLearning,
      convertAndAddLearning,
      deleteLearning,
      setLearningsList,
      addToLearningsList,
      setLearningItem,
      setLearningItemHypothesis,
      setBookmarkId,
      addAssistantSession,
      convertAndAddAssistantSession,
      deleteAssistantSession,
      setAssistantSessionsList,
      addToAssistantSessionsList,
      setAssistantSessionItem,
      addSentAssistantInvitation,
      convertAndAddSentAssistantInvitation,
      deleteSentAssistantSession,
      setSentAssistantInvitationsList,
      addToSentAssistantInvitationsList,
      setNetworkOrganizationsList,
      setSuggestions
    };
  })
  .views((self) => {
    return {
      get isAdmin(): boolean {
        if (!self.organization) {
          return false;
        }
        return self.organization.access_level === 'superadmin' ? true : false;
      },

      get currentOrganization(): OrganizationModelType | undefined {
        return self.organization;
      },
      get currentOrganizationId(): number | undefined {
        return self.organization?.id;
      },
      get isAgency(): boolean {
        return self.organization?.is_agency ? true : false;
      },
      get isOrgAgency(): boolean {
        if (!self.organization) {
          return false;
        }

        if (
          self.user!.agent &&
          this.ownAgency?.id === this.currentOrganization?.agency_id
        ) {
          return true;
        }

        return false;
      },
      get isOrgAdmin(): boolean {
        if (!self.organization) {
          return false;
        }

        if (
          self.user!.agent &&
          this.ownAgency?.id === this.currentOrganization?.agency_id
        )
          return true;

        return self.organization.access_level === 'admin' ||
          self.organization.access_level === 'superadmin'
          ? true
          : false;
      },
      get isOrgEditor(): boolean {
        if (!self.organization) {
          return false;
        }
        if (
          self.user!.agent &&
          this.ownAgency?.id === this.currentOrganization?.agency_id
        )
          return true;
        return self.organization.access_level === 'user' ||
          self.organization.access_level === 'admin' ||
          self.organization.access_level === 'superadmin'
          ? true
          : false;
      },
      get isOrgViewerButNotEditor(): boolean {
        if (!self.organization) {
          return false;
        }
        return self.organization.access_level === 'viewer' ? true : false;
      },
      get isOrgViewer(): boolean {
        if (!self.organization) {
          return false;
        }
        return self.organization.access_level === 'viewer' ||
          self.organization.access_level === 'user' ||
          self.organization.access_level === 'admin' ||
          self.organization.access_level === 'superadmin'
          ? true
          : false;
      },

      get ownOrgs(): OrganizationModelType[] {
        const orgs: OrganizationModelType[] = [];

        if (self.organizations.size < 1) {
          return orgs;
        }

        for (const org of self.organizations.values()) {
          if (!org.access_level || org.access_level === 'disabled') {
            continue;
          }

          orgs.push(org);
        }

        return orgs;
      },
      get sortedOwnOrgs(): OrganizationModelType[] {
        const orgs = this.ownOrgs;
        orgs.sort(sortByField('name'));
        return orgs;
      },
      get hasOwnOrgs(): boolean {
        return this.ownOrgs.length > 0;
      },
      get onlyOwnOrg(): OrganizationModelType | undefined {
        const orgs = this.ownOrgs;

        if (orgs.length === 1) {
          return orgs[0];
        }

        return undefined;
      },
      get ownAgency(): OrganizationModelType | undefined {
        if (!self.user?.agent || !self.organizations) {
          return undefined;
        }

        for (const org of self.organizations.values()) {
          if (
            org.is_agency &&
            org.access_level &&
            org.access_level !== 'disabled'
          ) {
            return org;
          }
        }

        return undefined;
      },
      get currentProject(): ProjectModelType | undefined {
        return self.project;
      },
      get currentProjectId(): number | undefined {
        return self.project?.id;
      },
      get isProjectMember(): boolean {
        return self.project?.access_level === 'member' ? true : false;
      },
      get isProjectEditor(): boolean {
        return this.isOrgAdmin || (this.isOrgEditor && this.isProjectMember);
      },

      get currentUser(): UserModelType | undefined {
        return self.user;
      },
      get currentUserId(): number | undefined {
        return self.user?.id;
      },

      get organizationsLength(): number {
        return self.organizations.size;
      },

      mayEdit(author?: any, viewerMayEdit: boolean = false): boolean {
        // TODO check project membership status?
        if (this.isOrgAdmin) {
          return true;
        }
        if (this.isOrgEditor) {
          return true;
        }

        if (
          !author ||
          !this.currentUser ||
          (!viewerMayEdit && this.isOrgViewerButNotEditor)
        ) {
          return false;
        }

        if (typeof author === 'number') {
          return author === this.currentUserId ? true : false;
        }
        return author.id === this.currentUserId ? true : false;
      },
      mayEditAsViewer(author?: any): boolean {
        return this.mayEdit(author, true);
      },
      mayComment() {
        // TODO may users comment on network project elements?
      },

      applyContext(
        organizationId?: number,
        projectId?: number
      ): { organizationId: number; projectId: number } {
        if (!organizationId) {
          organizationId = self.organization?.id;
        }
        if (!projectId) {
          projectId = self.project?.id;
        }

        if (!organizationId || !projectId) {
          throw new Error('Insufficient context');
        }

        return {
          organizationId,
          projectId
        };
      },
      get contextUri(): string {
        if (!self.organization?.id || !self.project?.id) {
          return '';
        }

        return ROUTE_LAB + '/' + self.organization.id + '/' + self.project.id;
      }
    };
  });

export type DataStoreType = typeof DataStore.Type;
export default DataStore;

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCreateProject } from 'contexts/CreateProjectContext';
import { useEffect, useState } from 'react';
import CardWrapper from 'components/CardWrapper';
import Input from 'components/Inputs/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import MainButton from 'components/MainButton';
import Typography from 'components/Typography';

export default function BusinessTargetAndFormatScreen() {
  const match = useRouteMatch<{ step: string }>();
  const history = useHistory();

  const {
    setCurrentStep,
    projectBusinessTarget,
    setProjectBusinessTarget,
    projectBusinessTargetFormat,
    setProjectBusinessTargetFormat,
    currentStep
  } = useCreateProject();
  const intl = useIntl();

  useEffect(() => {
    if (match.params.step !== currentStep.toString()) {
      setCurrentStep(Number(match.params.step));
    }
  }, [match.params.step]);

  const [tmpBusinessTarget, setTmpBusinessTarget] = useState(
    projectBusinessTarget || ''
  );
  const [tmpBusinessTargetFormat, setTmpBusinessTargetFormat] = useState(
    projectBusinessTargetFormat || ''
  );

  return (
    <div className="l-item-stack-regular">
      <CardWrapper>
        <div className="l-item-stack-regular">
          <Input
            label={intl.formatMessage({
              id: 'create project business target target label'
            })}
            name="business_target"
            placeholder={intl.formatMessage({
              id: 'create project business target target placeholder'
            })}
            autoFocus={true}
            normalFont={true}
            value={tmpBusinessTarget}
            tabIndex={1}
            onChange={(e) => setTmpBusinessTarget(e.target.value)}
          />
          <Typography opaque={true} noMargin={true}>
            <FormattedMessage id="create project business target target example" />
          </Typography>
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="l-item-stack-regular">
          <Input
            label={intl.formatMessage({
              id: 'create project business target format label'
            })}
            name="format"
            placeholder={intl.formatMessage({
              id: 'create project business target format placeholder'
            })}
            normalFont={true}
            value={tmpBusinessTargetFormat}
            tabIndex={2}
            onChange={(e) => setTmpBusinessTargetFormat(e.target.value)}
          />
          <Typography opaque={true} noMargin={true}>
            <FormattedMessage id="create project business target format example" />
          </Typography>
        </div>
      </CardWrapper>

      <div className="l-item-stack-horizontal l-item-stack-horizontal--flex-end">
        <MainButton
          secondary={true}
          onClick={() => {
            history.goBack();
            setCurrentStep(currentStep - 1);
          }}
        >
          <FormattedMessage id="Back" />
        </MainButton>
        <MainButton
          onClick={() => {
            setProjectBusinessTarget(tmpBusinessTarget);
            setProjectBusinessTargetFormat(tmpBusinessTargetFormat);
            setCurrentStep(currentStep + 1);
          }}
          disabled={!tmpBusinessTarget || !tmpBusinessTargetFormat}
        >
          <FormattedMessage id="Next" />
        </MainButton>
      </div>
    </div>
  );
}
